import { reactive, ref, computed } from 'vue'
import settings from '../settings'

export const EXTENSION_AUTH_TOKEN = settings.EXTENSION_AUTH_TOKEN
export const PRO_TARGETING_AUTH_TOKEN = settings.PRO_TARGETING_AUTH_TOKEN

type Params = Record<string, undefined | null | string | number | (string | number)[]>
type Method = 'get' | 'put' | 'post' | 'patch' | 'delete' | 'GET' | 'PUT' | 'POST' | 'PATCH' | 'DELETE'

export interface RequestOptions {
  params?: Params
  data?: unknown
  headers?: Record<string, string>
  onUploadProgress?: (e: ProgressEvent) => void
}

// TODO: Decouple api client from project settings
const baseUrl = ref(window.location.href.includes('xdeepthought.com') ? settings.API_ROOT_DEEPTHOUGHT : settings.API_ROOT)
const headers = reactive<Record<string, string>>({})
const callsInProgress = ref<{ age: number }[]>([])

const progressValue = computed(() => {
  const nCalls = callsInProgress.value.length
  if (nCalls === 0) return 1
  return Math.min(
    ...callsInProgress.value.map((call) => {
      const p = 0.1 ** (500 / call.age) // nice formula for a curve that never completes
      const q = 0.1 + 0.9 * p // start at 10% and finish at 90%
      return q
    })
  )
})

function buildUrl(path: string, params: Params) {
  let url = path
  // Convert relative URL into absolute URL to make sure the request will always be done to an absolute URL
  if (!url.startsWith('http')) {
    url = baseUrl.value + (url.startsWith('/') ? url : '/' + url)
  }

  // Serialize url params
  const paramsFlat = Object.entries(params).flatMap(([k, v]) =>
    [v]
      .flat()
      .filter((x) => x != null)
      .map((x) => [k, '' + x])
  )
  if (paramsFlat.length) url += '?' + new URLSearchParams(paramsFlat)

  return url
}

async function request(method: Method, url: string, opt?: RequestOptions) {
  const xhr = new XMLHttpRequest()
  return new Promise<any>((resolve, reject) => {
    const params = opt?.params || {}
    let data = opt?.data

    const fileEntry = Object.entries((data as any) || {}).find((x) => x[1] instanceof File)
    if (fileEntry) {
      Object.assign(params, opt?.data)
      delete params[fileEntry[0]]
      data = fileEntry[1]
    }

    const fullUrl = buildUrl(url, params)
    xhr.withCredentials = true
    xhr.open(method.toUpperCase(), fullUrl)

    for (const name in headers) {
      xhr.setRequestHeader(name, headers[name])
    }

    for (const name in opt?.headers) {
      xhr.setRequestHeader(name, opt.headers[name])
    }

    // Only set 'Content-Type' header when data is not FormData. Else let the browser set it.
    // See similar bugfix in Axios: https://github.com/axios/axios/pull/22
    if (data instanceof FormData || data instanceof File) {
      xhr.send(data)
    } else {
      xhr.setRequestHeader('Content-Type', 'application/json')
      xhr.send(JSON.stringify(data))
    }
    xhr.onload = () => {
      callsInProgress.value.splice(callsInProgress.value.indexOf(callobj), 1)
      clearInterval(interval)

      let data = xhr.responseText
      try {
        data = JSON.parse(xhr.responseText)
      } catch (e: unknown) {}
      if (xhr.status >= 400) {
        reject(new RequestError(xhr, data))
      } else {
        resolve(data)
      }
    }
    xhr.onerror = reject
    if (opt?.onUploadProgress) xhr.onprogress = opt.onUploadProgress

    const started = Date.now()
    const callobj = reactive({ age: 0 })
    const interval = setInterval(() => {
      callobj.age = Date.now() - started
    }, 500)
    callsInProgress.value.push(callobj)
  }).catch((e) => {
    if (e instanceof ProgressEvent) {
      throw new RequestError(xhr, 'It seems that something went wrong in our servers')
    }
    throw e
  })
}

interface EndpointData {
  path: string
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
  paramsType?: { query?: any; body?: any; path?: any }
  responseType: any
}

export const api = {
  progressValue,
  baseUrl,
  callsInProgress,
  headers,

  async get(url: string, params?: Params, opt?: RequestOptions) {
    return request('get', url, { params, ...opt })
  },
  async post(url: string, data?: any, params?: Params, opt?: RequestOptions) {
    return request('post', url, { data, params, ...opt })
  },
  async put(url: string, data?: any, params?: Params, opt?: RequestOptions) {
    return request('put', url, { data, params, ...opt })
  },
  async patch(url: string, data?: any, params?: Params, opt?: RequestOptions) {
    return request('patch', url, { data, params, ...opt })
  },
  async delete(url: string, params?: Params) {
    return request('delete', url, { params })
  },
  async call<T extends EndpointData>(
    endpoint: T,
    params?: T['paramsType'],
    opt?: RequestOptions
  ): Promise<T['responseType']> {
    const { method, path } = endpoint
    let url: string = path
    for (const [key, value] of Object.entries(params?.path ?? {})) {
      url = url.replace(`{${key}}`, `${value}`) // Fill path parameters
    }
    return request(method, url, { params: params?.query ?? {}, data: params?.body ?? {}, ...opt })
  },

  setHeader(key: string, value: string) {
    headers[key] = value
  },
  removeHeader(key: string) {
    delete headers[key]
  },
}

export class RequestError extends Error {
  status: number

  constructor(public response: XMLHttpRequest, public data: unknown) {
    function unpackMessages(v: any): string {
      if (typeof v === 'string') return v
      if (Array.isArray(v)) return v.map(unpackMessages).join(', ')
      if (v.detail) return unpackMessages(v.detail)
      if (v.errors) return unpackMessages(v.errors)
      return Object.entries(v)
        .map(([k, s]) => {
          if (k === 'non_field_errors') return s
          return k.slice(0, 1).toUpperCase() + k.slice(1) + ': ' + unpackMessages(s)
        })
        .join('.\n')
    }
    const msg = unpackMessages(data)
    super(msg)
    this.status = response.status
  }
}
