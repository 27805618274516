// This API schema was generated automatically by TypescriptSchemaGenerator
export type AccountApps = {
  app_store_identifier: string
  app_id: string
  os_type: 'iOS'|'Android'
}
export type PollCard = {
  account_id: string
  name: string
  card_type: string
  card_uri: string
  duration_in_minutes: number
  first_choice: string
  second_choice: string
  third_choice: string|null
  fourth_choice: string|null
  media_key: string|null
}
export type Card = {
  account_id: string
  name: string
  card_type: string
  card_uri: string
}
export type Tweet = {
  id_str: string
  account_id: string
  text: string|null
  name: string|null
  nullcast: boolean
  media_keys: string[]|null
  card_uri: string|null
  poll_card: PollCard|null
  card: Card|null
  created_at: string
}
export type ObjectStorySpec = {
  instagram_actor_id: string|null
  page_id: string
}
export type FbCreative = {
  id: string
  account_id: string
  name: string
  title: string|null
  body: string|null
  effective_object_story_id: string|null
  call_to_action_type: string|null
  object_type: 'APPLICATION'|'DOMAIN'|'EVENT'|'OFFER'|'PAGE'|'PHOTO'|'SHARE'|'STATUS'|'STORE_ITEM'|'VIDEO'|'INVALID'|'PRIVACY_CHECK_FAIL'|'POST_DELETED'
  actor_id: string
  effective_instagram_story_id: string|null
  instagram_actor_id: string|null
  status: 'ACTIVE'|'IN_PROCESS'|'WITH_ISSUES'|'DELETED'
  video_id: string|null
  object_id: string|null
  link_url: string|null
  object_url: string|null
  object_story_spec: ObjectStorySpec|null
}
export type AdCreative = {
  network: 'twitter'|'facebook'
  account_id: string
  preview_result: string|null
  name: string
  creative_id: string
  created: string|null
  tweet: Tweet|null
  fb_creative: FbCreative|null
}
export type PollCardInput = {
  name: string
  duration_in_minutes: number
  first_choice: string
  second_choice: string
  third_choice: string|null
  fourth_choice: string|null
  media_key: string|null
}
export type DestinationInput = {
  type: 'WEBSITE'
  url: string
}
export type ComponentInput = {
  type: 'MEDIA'|'DETAILS'|'SWIPEABLE_MEDIA'
  media_key?: string|null
  media_keys?: string[]|null
  title?: string|null
  destination?: DestinationInput|null
}
export type CardInput = {
  name: string
  components: ComponentInput[]
}
export type TweetInput = {
  text: string|null
  name: string|null
  nullcast?: boolean
  media_keys?: string[]|null
  card_uri?: string|null
  poll_card: PollCardInput|null
  card: CardInput|null
}
export type AdCreativeInput = {
  network: 'twitter'|'facebook'
  objective: string
  account_id: string
  ad_account: string
  preview_only: boolean
  tweet: TweetInput|null
}
export type _TwitterImage = {
  media_key: string
  created_at: string
  updated_at: string
  media_status: string
  media_type: 'IMAGE'|'VIDEO'
  media_url: string
  tweeted: 'true'|null
  file_name: string|null
  name: string|null
}
export type _TwitterVideo = {
  media_key: string
  created_at: string
  updated_at: string
  media_status: string
  media_type: 'IMAGE'|'VIDEO'
  media_url: string
  aspect_ratio: string
  duration: number|null
  poster_media_url: string|null
  poster_media_key: string|null
  file_name: string|null
}
export type _FacebookImage = {
  id: string
  permalink_url: string
  created_time: string
  updated_time: string
  name: string
  hash: string
  width: number
  height: number
  status: string
}
export type _FacebookPhase = {
  status: string
}
export type _FacebookPublishingPhase = {
  status: string
  publish_status: string
  publish_time: string
}
export type _FacebookVideoStatus = {
  video_status: string
  uploading_phase: _FacebookPhase
  processing_phase: _FacebookPhase
  publishing_phase: _FacebookPublishingPhase
}
export type _FacebookVideo = {
  id: string
  permalink_url: string
  created_time: string
  updated_time: string
  length: number
  title: string|null
  source: string
  published: boolean
  is_instagram_eligible: boolean
  status: _FacebookVideoStatus
}
export type AdMedia = {
  network: 'twitter'|'facebook'
  account_id: string
  name: string
  media_type: 'image'|'video'
  twitter_image: _TwitterImage|null
  twitter_video: _TwitterVideo|null
  facebook_image: _FacebookImage|null
  facebook_video: _FacebookVideo|null
  id: string
  sdb_id: string
  modified: string
  status: string
  thumbnail_url: string
  video_url: string|null
}
export type AdMediaInput = {
  account_id: string
  name: string
  filename: string
  file: any
  media_type: 'image'|'video'
}
export type GetAdSetListQuery = {
  limit?: number
  offset?: number
}
export type CumulativeStats = {
  refdate: string|null
  network: 'facebook'|'twitter'|'linkedin'
  is_latest: boolean
  impressions: number
  engagements: number
  engagement_rate: number|null
  cost_per_engagement: number|null
  social_spend: number|null
  clicks: number
  click_through_rate: number|null
  spent: number|null
  billed_charge_local_micro: number
  reach: number
  call_to_action_clicks: number
  canvas_avg_view_percent: number|null
  canvas_avg_view_time: number|null
  canvas_component_avg_pct_view: number|null
  cost_per_10_sec_video_view: number|null
  cost_per_action_type: number|null
  cost_per_estimated_ad_recallers: number|null
  cost_per_inline_link_click: number|null
  cost_per_inline_post_engagement: number|null
  cost_per_total_action: number|null
  cost_per_unique_action_type: number|null
  cost_per_unique_click: number|null
  cost_per_unique_inline_link_click: number|null
  cpc_fb: number|null
  cpm_fb: number|null
  cpm: number|null
  cpp: number|null
  ctr: number|null
  deeplink_clicks: number
  estimated_ad_recall_rate: number|null
  estimated_ad_recallers: number
  frequency: number|null
  impressions_dummy: number
  inline_link_click_ctr: number|null
  inline_link_clicks: number
  inline_post_engagement: number
  relevance_score: number|null
  social_clicks: number
  social_impressions: number
  social_reach: number
  total_action_value: number|null
  total_actions: number
  total_unique_actions: number
  total_audience_reach: number|null
  unique_actions: number
  unique_clicks: number
  unique_ctr: number|null
  unique_inline_link_click_ctr: number|null
  unique_inline_link_clicks: number
  unique_link_clicks_ctr: number|null
  unique_social_clicks: number
  video_10_sec_watched_actions: number
  video_15_sec_watched_actions: number
  video_30_sec_watched_actions: number
  video_avg_percent_watched_actions: number|null
  video_avg_time_watched_actions: number|null
  video_p100_watched_actions: number
  video_p25_watched_actions: number
  video_p50_watched_actions: number
  video_p75_watched_actions: number
  video_p95_watched_actions: number
  website_clicks: number
  website_ctr: number|null
  comment: number
  landing_page_view: number
  like: number
  unlike: number
  link_click: number
  link_clicks: number
  cost_per_link_click: number|null
  offsite_conversion: number
  offsite_conversion_label: string|null
  page_engagement: number
  photo_view: number
  post_engagement: number
  post_reaction: number
  share: number
  video_view: number
  video_view_rate: number|null
  cost_per_video_view: number|null
  mention: number
  actions: object|null
  follows: number
  unfollows: number
  retweets: number
  replies: number
  media_engagements: number
  media_views: number
  url_clicks: number
  auto_created_conversion_sign_up_initiated: number
  video_views_25: number
  video_views_50: number
  video_views_75: number
  video_views_100: number
  video_3s100pct_views: number
  video_6s50pct_views: number
  video_6s_views: number|null
  video_15s_views: number|null
  video_content_starts: number
  video_mrc_views: number
  video_total_views: number
  tweets_send: number
  poll_card_vote: number
  app_clicks: number
  card_engagements: number
  conversion_custom: number|null
  conversion_downloads: number|null
  conversion_sign_ups: number|null
  conversion_site_visits: number|null
  conversion_purchases: number|null
  auto_created_conversion_purchase: number
  carousel_swipes: number
  video_cta_clicks: number
  auto_created_conversion_download: number|null
  promoted_tweet_profile_impressions: number|null
  promoted_tweet_search_impressions: number|null
  promoted_tweet_timeline_impressions: number|null
  promoted_tweet_app_install_attempts: number|null
  promoted_tweet_app_open_attempts: number|null
  promoted_tweet_search_url_clicks: number|null
  promoted_tweet_timeline_url_clicks: number|null
  promoted_account_follows: number|null
  promoted_account_follow_rate: number|null
  promoted_tweet_search_engagements: number|null
  promoted_tweet_timeline_engagements: number|null
  promoted_tweet_profile_engagements: number|null
  promoted_video_total_views: number|null
  promoted_tweet_profile_url_clicks: number|null
  qualified_impressions: number|null
  mobile_conversion_installs_post_views: number
  mobile_conversion_installs_post_engagements: number
  app_installs: number
  campaign: number|null
  adset: number|null
  ad: number|null
  segmentation: number|null
}
export type Ad = {
  id: number
  stats_first: CumulativeStats[]
  stats_last: CumulativeStats[]
  name: any
  created: string
  modified: string
  entity_type: string|null
  ad_id: string
  status: 'active'|'paused'|'deleted'|'archived'|'finished'|null
  creative_id: string|null
  creative: object|null
  adset: number
}
export type Adset = {
  id: number
  stats_first: CumulativeStats[]
  stats_last: CumulativeStats[]
  max_bid: any
  budget: any
  status: any
  lifetime_budget: any
  daily_budget: any
  conversion_window: any
  ads: Ad[]
  created: string
  modified: string
  _campaign_id: string
  name: string|null
  adset_id: string
  targeting: object|null
  start_time: string|null
  end_time: string|null
  budget_type: 'daily'|'lifetime'
  _ads: object|null
  bid_amount: number
  is_autobid: boolean
  rule: string|null
  event_id: string|null
  pixel_id: string|null
  eventtype: string|null
  custom_event_type: string|null
  click_through_days: number
  view_through_days: number
  placement: object|null
  uses_sdb_audience: boolean|null
  uses_sdb_native_pack: boolean|null
  targeting_size: object|null
  campaign: number
  _audience: number|null
}
export type AdInput = {
  entity_type?: string|null
  ad_id: string
  status?: 'active'|'paused'|'deleted'|'archived'|'finished'|null
  creative_id?: string|null
  creative?: object|null
  adset: number
}
export type AdsetInput = {
  ads: AdInput[]
  _campaign_id: string
  name?: string|null
  adset_id: string
  targeting?: object|null
  start_time?: string|null
  end_time?: string|null
  budget_type?: 'daily'|'lifetime'
  _ads?: object|null
  bid_amount?: number
  is_autobid?: boolean
  rule?: string|null
  event_id?: string|null
  pixel_id?: string|null
  eventtype?: string|null
  custom_event_type?: string|null
  click_through_days?: number
  view_through_days?: number
  placement?: object|null
  uses_sdb_audience?: boolean|null
  uses_sdb_native_pack?: boolean|null
  targeting_size?: object|null
  campaign: number
  _audience?: number|null
}
export type DeleteAdSetInstanceQuery = GetAdSetListQuery
export type GetAdSetInstanceQuery = GetAdSetListQuery
export type WriteTwitterAdsetInput = {
  creatives: string[]
  end_time?: string|null
  is_autobid?: boolean
  max_bid?: number|null
  name: string
  start_time: string
  country?: string|null
  age_min?: number|null
  age_max?: number|null
  locations?: any[]
  daily_budget?: number|null
  exclude_audiences?: string[]
  include_audiences?: string[]
  handles?: string[]
  keywords?: string[]
  hashtags?: string[]
  iab_targeting?: string[]
  lifetime_budget: number
  status?: 'active'|'paused'
}
export type PatchAdSetInstanceQuery = GetAdSetListQuery
export type WriteTwitterAdset = {
  end_time: string|null
  is_autobid: boolean
  max_bid: number|null
  name: string
  start_time: string
  country: string|null
  age_min: number|null
  age_max: number|null
  locations: any[]
  daily_budget: number|null
  exclude_audiences: string[]
  include_audiences: string[]
  handles: string[]
  keywords: string[]
  hashtags: string[]
  iab_targeting: string[]
  lifetime_budget: number
  status: 'active'|'paused'
}
export type PutAdSetInstanceQuery = GetAdSetListQuery
export type AdvertiserBusinessCategories = {
  category_id: string
  name: string
  iab_categories: any[]
}
export type AppEvents = {
  event_id: string
  conversion_type: 'ACHIEVEMENT_UNLOCKED'|'ADDED_PAYMENT_INFO'|'ADD_TO_CART'|'ADD_TO_WISHLIST'|'CHECKOUT_INITIATED'|'CONTENT_VIEW'|'INSTALL'|'INVITE'|'LEVEL_ACHIEVED'|'LOGIN'|'PURCHASE'|'RATED'|'RESERVATION'|'RE_ENGAGE'|'SEARCH'|'SHARE'|'SIGN_UP'|'SPENT_CREDITS'|'TUTORIAL_COMPLETE'|'UPDATE'
  status: string
}
export type GetAudienceCategoryListQuery = {
  team?: string
  id?: string
}
export type AudienceCategory = {
  id: number
  created: string
  modified: string
  name: string
  is_default_category: boolean
}
export type GetAudienceCategoryInstanceQuery = GetAudienceCategoryListQuery
export type GetAudienceConfigListQuery = {
  limit?: number
  offset?: number
  search?: string
  ordering?: string
  team?: string
  categories?: string
  regions?: string
  id?: string
}
export type AudiencePlan = {
  id: number
  created: string
  modified: string
  name: string
  description: string
  public: boolean
  is_active: boolean
}
export type TeamPublic = {
  id: number
  slug: string|null
  name: string
  member_count: number
  logo: string|null
}
export type AudienceRegion = {
  id: number
  country_codes: (string)[]
  is_single_country: boolean
  name: string
  m49_code: string|null
}
export type AudienceConfig = {
  id: number
  created: string
  modified: string
  name: string
  description: string|null
  audience_plan: AudiencePlan
  size: number
  approximate_count: string
  is_active: boolean
  created_by_team: TeamPublic
  categories: AudienceCategory[]
  regions: AudienceRegion[]
  plan_type: string
  top_influencers: any[]
  display_name: string
  audience_estimation: string
  native_estimation: string
  insights_id: number|null
  has_native_pack: boolean
}
export type AudienceConfigInput = {
  name: string
  description?: string|null
  approximate_count: string
  is_active: boolean
  parameters: object
  category_ids: number[]
  region_ids: number[]
  created_by_team_id: number
  audience_plan_id: string
  insights_id: number|null
}
export type AutoCompleteInput = {
  partial_query: string
}
export type AutoComplete = {
  queries: string[]
}
export type SearchPerspectivesInput = {
  keyword: string
}
export type SearchPerspectives = {
  entities: any[]
  countries: string[]
  audience_type: string
  spelling_correction: string
  inapropriate_words_message: string
  account_types: number[]
}
export type GetAudienceConfigInstanceQuery = GetAudienceConfigListQuery
export type Insights = {
  id: number
  insights_counts: any
  is_super_insights: boolean
  influencer_groups: string[]
  created: string
  modified: string
  status: any|null
  gender: { label: string; value: number; valueText: string }[]|null
  countries: { label: string; value: number; valueText: string; alpha_2: string|null }[]|null
  regions: { label: string; value: number; valueText: string }[]|null
  cities: { label: string; value: number; valueText: string; long: number|null; lat: number|null }[]|null
  specific_interests: { label: string; value: number; valueText: string }[]|null
  interests: { label: string; value: number; valueText: string }[]|null
  interest_fields: { label: string; value: number; valueText: string }[]|null
  professions: { label: string; value: number; valueText: string }[]|null
  profession_fields: { label: string; value: number; valueText: string }[]|null
  income_level: { label: string; value: number; valueText: string }[]|null
  education_level: { label: string; value: number; valueText: string }[]|null
  socioeconomic_status: { label: string; value: number; valueText: string }[]|null
  bio_words: { label: string; value: number; valueText: string }[]
  bio_hashtags: { label: string; value: number; valueText: string }[]|null
  bio_chunks: any
  tweet_words: { label: string; value: number; valueText: string }[]|null
  tweet_keywords: { label: string; value: number; valueText: string }[]|null
  mentioned_hashtags: { label: string; value: number; valueText: string }[]|null
  mentioned_accounts: { id: number; handle: string; bio: string|null; followers: number; name?: string; friends?: number; picture?: string|null; count: number }[]|null
  most_liked_accounts: { id: number; handle: string; bio: string|null; followers: number; name?: string; friends?: number; picture?: string|null; count: number }[]|null
  most_liked_tweets: { tweet_id: string; handle: string; tweet_text: string; likes: number; retweets: number; picture?: string|null; count: number }[]|null
  tweets_most_liked: { tweet_id: string; handle: string; tweet_text: string; likes: number; retweets: number; picture?: string|null; count: number }[]|null
  tweets_most_retweeted: { tweet_id: string; handle: string; tweet_text: string; likes: number; retweets: number; picture?: string|null; count: number }[]|null
  most_retweeted_accounts: { id: number; handle: string; bio: string|null; followers: number; name?: string; friends?: number; picture?: string|null; count: number }[]|null
  influencers: { id: number; handle: string; bio: string|null; followers: number; name: string; friends: number; picture: string|null; connectivity: number; relevancy: number; base_audience_count?: number|null; is_followed?: boolean|null; gender?: string; tertiary_sub_interest?: string|null; secondary_sub_interest?: string|null; profession?: string|null; sub_interest?: string|null; country?: string|null; country_name?: string|null; region?: string|null; city?: string|null; country_id?: number|null; interest?: string|null; following_tertiary_sub_interest?: string|null; following_secondary_sub_interest?: string|null; following_profession?: string|null; following_sub_interest?: string|null; following_country?: string|null; following_interest?: string|null; connectivity_ranking?: number|null; connectivity_percentage: number|null; relevancy_percentage?: number|null; relevancy_ranking: number|null; age?: string|null; groups?: string|null[]|null; rscore?: number|null; cscore?: number|null; weighted_connectivity?: number|null; likes_per_tweet?: number|null; tweets_per_month?: number|null; retweets_per_tweet?: number|null; active?: boolean|null; activetweeter?: boolean|null; tweet_id?: string|null; tweet_date?: string|null; facebook?: string|null; instagram?: string|null; linkedin?: string|null; youtube?: string|null; x?: number|null; y?: number|null; height?: number|null; membership?: number|null; size?: number|null }[]
  audience_estimation: { min: number; max: number; warning: string|null }|null
  targeting_package: { hashtags: { label: string; size: number|null; deselected: boolean|null }[]; handles: { picture: string|null; id: number; handle: string; bio: string|null; name: string; size: number|null; followers: number; relevancy: number; connectivity: number|null; country: string|null; deselected: boolean|null }[]; handles_ordered_by: string|null; keywords: { label: string; size: number|null; deselected: boolean|null }[]; conversation_topics: { label: string; size: number|null; targeting_value: string|null }[]; interests: { label: string; size: number|null; targeting_value: string|null }[]; movies_tv_shows: { label: string; size: number|null; targeting_value: string|null }[]; custom_audiences: string[]; custom_audiences_deselected: string[]|null; languages: { label: string; size: number|null; targeting_value: string|null }[]; network_operators: { label: string; size: number|null; targeting_value: string|null }[]; placements: string[]; gender: string; age: string; locations: string[] }|null
  targeting_package_size: { min: number; max: number }|null
  related_communities: null|string[]|null
  core_accounts: { id: number; handle: string; bio: string|null; followers: number; name: string; friends: number; picture?: string|null; likes_per_month?: number|null; tweets_per_month?: number|null; activity_score?: number|null; likes_count?: number|null; tweets_count?: number|null; site?: string|null; gender?: string|null; profession?: string|null; interest?: string|null; sub_interest?: string|null; country?: string|null; region?: string|null; city?: string|null; age?: string|null; secondary_sub_interest?: string|null; tertiary_sub_interest?: string|null }[]
  age: { label: string; value: number; valueText: string }[]|null
  sites: { label: string; value: number; valueText: string }[]|null
  accounts_count: { InitialSearch: number|null; JobTitles: number|null; Influencers: number|null; FollowersCount: number|null }|null
  suggestions: { query: string; metadata: { id: number; handle: string; bio: string|null; followers: number; name: string; friends: number; picture: string|null; connectivity: number; relevancy: number; base_audience_count?: number|null; is_followed?: boolean|null; gender?: string; tertiary_sub_interest?: string|null; secondary_sub_interest?: string|null; profession?: string|null; sub_interest?: string|null; country?: string|null; country_name?: string|null; region?: string|null; city?: string|null; country_id?: number|null; interest?: string|null; following_tertiary_sub_interest?: string|null; following_secondary_sub_interest?: string|null; following_profession?: string|null; following_sub_interest?: string|null; following_country?: string|null; following_interest?: string|null; connectivity_ranking?: number|null; connectivity_percentage: number|null; relevancy_percentage?: number|null; relevancy_ranking: number|null; age?: string|null; groups?: string|null[]|null; rscore?: number|null; cscore?: number|null; weighted_connectivity?: number|null; likes_per_tweet?: number|null; tweets_per_month?: number|null; retweets_per_tweet?: number|null; active?: boolean|null; activetweeter?: boolean|null; tweet_id?: string|null; tweet_date?: string|null; facebook?: string|null; instagram?: string|null; linkedin?: string|null; youtube?: string|null; x?: number|null; y?: number|null; height?: number|null; membership?: number|null; size?: number|null }[] }[]|null
}
export type AudienceConfigDetail = {
  id: number
  created: string
  modified: string
  name: string
  description: string|null
  audience_plan: AudiencePlan
  size: number
  approximate_count: string
  is_active: boolean
  created_by_team: TeamPublic
  categories: AudienceCategory[]
  regions: AudienceRegion[]
  plan_type: string
  top_influencers: any[]
  display_name: string
  audience_estimation: string
  native_estimation: string
  insights_id: number|null
  has_native_pack: boolean
  insights: Insights|null
}
export type PutAudienceConfigInstanceQuery = GetAudienceConfigListQuery
export type AudiencePlanInput = {
  name: string
  description: string
  public?: boolean
  is_active?: boolean
}
export type GetAudienceListQuery = {
  limit?: number
  offset?: number
  search?: string
  ordering?: string
  team?: string
  id?: string
  ad_account?: string
  targetable?: string
}
export type AudienceConfigBasic = {
  id: number
  name: string|null
  description: string|null
  audience_plan: number|null
  is_active: boolean
}
export type Audience = {
  id: number
  created: string
  modified: string
  network: any
  imported: boolean
  audience_id: string|null
  name: string
  targeting: object|null
  approximate_count: number
  is_global_audience: boolean
  config: AudienceConfigBasic
  uploaded_insights_id: number|null
  account_id: string
  uploaded_audience_id: number|null
  targetable: boolean
  reasons_not_targetable: string[]
  top_influencers: any[]
  targetable_keywords: any[]
  targetable_hashtags: any[]
  targeting_package_size: any
}
export type GetAudienceInstanceQuery = GetAudienceListQuery
export type BuildAudienceInput = {
  plan_name: string
  plan_type: string|null
  input_query: string|null
  keywords: string[]
  input_countries?: string[]|null
  exclude_keywords?: string[]|null
  types?: string[]|null
  following_types?: any[]|null
  selected_influencers?: any[]|null
  engagement?: 'high'|'medium'|'low'
  size?: string|null
  config_id: number|null
}
export type BuildAudience = {
  id: number
  created: string
  modified: string
  status: any|null
  gender: { label: string; value: number; valueText: string }[]|null
  countries: { label: string; value: number; valueText: string; alpha_2: string|null }[]|null
  regions: { label: string; value: number; valueText: string }[]|null
  cities: { label: string; value: number; valueText: string; long: number|null; lat: number|null }[]|null
  specific_interests: { label: string; value: number; valueText: string }[]|null
  interests: { label: string; value: number; valueText: string }[]|null
  interest_fields: { label: string; value: number; valueText: string }[]|null
  professions: { label: string; value: number; valueText: string }[]|null
  profession_fields: { label: string; value: number; valueText: string }[]|null
  income_level: { label: string; value: number; valueText: string }[]|null
  education_level: { label: string; value: number; valueText: string }[]|null
  socioeconomic_status: { label: string; value: number; valueText: string }[]|null
  bio_words: { label: string; value: number; valueText: string }[]
  bio_hashtags: { label: string; value: number; valueText: string }[]|null
  bio_chunks: any
  tweet_words: { label: string; value: number; valueText: string }[]|null
  tweet_keywords: { label: string; value: number; valueText: string }[]|null
  mentioned_hashtags: { label: string; value: number; valueText: string }[]|null
  mentioned_accounts: { id: number; handle: string; bio: string|null; followers: number; name?: string; friends?: number; picture?: string|null; count: number }[]|null
  most_liked_accounts: { id: number; handle: string; bio: string|null; followers: number; name?: string; friends?: number; picture?: string|null; count: number }[]|null
  most_liked_tweets: { tweet_id: string; handle: string; tweet_text: string; likes: number; retweets: number; picture?: string|null; count: number }[]|null
  tweets_most_liked: { tweet_id: string; handle: string; tweet_text: string; likes: number; retweets: number; picture?: string|null; count: number }[]|null
  tweets_most_retweeted: { tweet_id: string; handle: string; tweet_text: string; likes: number; retweets: number; picture?: string|null; count: number }[]|null
  most_retweeted_accounts: { id: number; handle: string; bio: string|null; followers: number; name?: string; friends?: number; picture?: string|null; count: number }[]|null
  influencers: { id: number; handle: string; bio: string|null; followers: number; name: string; friends: number; picture: string|null; connectivity: number; relevancy: number; base_audience_count?: number|null; is_followed?: boolean|null; gender?: string; tertiary_sub_interest?: string|null; secondary_sub_interest?: string|null; profession?: string|null; sub_interest?: string|null; country?: string|null; country_name?: string|null; region?: string|null; city?: string|null; country_id?: number|null; interest?: string|null; following_tertiary_sub_interest?: string|null; following_secondary_sub_interest?: string|null; following_profession?: string|null; following_sub_interest?: string|null; following_country?: string|null; following_interest?: string|null; connectivity_ranking?: number|null; connectivity_percentage: number|null; relevancy_percentage?: number|null; relevancy_ranking: number|null; age?: string|null; groups?: string|null[]|null; rscore?: number|null; cscore?: number|null; weighted_connectivity?: number|null; likes_per_tweet?: number|null; tweets_per_month?: number|null; retweets_per_tweet?: number|null; active?: boolean|null; activetweeter?: boolean|null; tweet_id?: string|null; tweet_date?: string|null; facebook?: string|null; instagram?: string|null; linkedin?: string|null; youtube?: string|null; x?: number|null; y?: number|null; height?: number|null; membership?: number|null; size?: number|null }[]
  audience_estimation: { min: number; max: number; warning: string|null }|null
  targeting_package: { hashtags: { label: string; size: number|null; deselected: boolean|null }[]; handles: { picture: string|null; id: number; handle: string; bio: string|null; name: string; size: number|null; followers: number; relevancy: number; connectivity: number|null; country: string|null; deselected: boolean|null }[]; handles_ordered_by: string|null; keywords: { label: string; size: number|null; deselected: boolean|null }[]; conversation_topics: { label: string; size: number|null; targeting_value: string|null }[]; interests: { label: string; size: number|null; targeting_value: string|null }[]; movies_tv_shows: { label: string; size: number|null; targeting_value: string|null }[]; custom_audiences: string[]; custom_audiences_deselected: string[]|null; languages: { label: string; size: number|null; targeting_value: string|null }[]; network_operators: { label: string; size: number|null; targeting_value: string|null }[]; placements: string[]; gender: string; age: string; locations: string[] }|null
  targeting_package_size: { min: number; max: number }|null
  related_communities: null|string[]|null
  core_accounts: { id: number; handle: string; bio: string|null; followers: number; name: string; friends: number; picture?: string|null; likes_per_month?: number|null; tweets_per_month?: number|null; activity_score?: number|null; likes_count?: number|null; tweets_count?: number|null; site?: string|null; gender?: string|null; profession?: string|null; interest?: string|null; sub_interest?: string|null; country?: string|null; region?: string|null; city?: string|null; age?: string|null; secondary_sub_interest?: string|null; tertiary_sub_interest?: string|null }[]
  age: { label: string; value: number; valueText: string }[]|null
  sites: { label: string; value: number; valueText: string }[]|null
  accounts_count: { InitialSearch: number|null; JobTitles: number|null; Influencers: number|null; FollowersCount: number|null }|null
  suggestions: { query: string; metadata: { id: number; handle: string; bio: string|null; followers: number; name: string; friends: number; picture: string|null; connectivity: number; relevancy: number; base_audience_count?: number|null; is_followed?: boolean|null; gender?: string; tertiary_sub_interest?: string|null; secondary_sub_interest?: string|null; profession?: string|null; sub_interest?: string|null; country?: string|null; country_name?: string|null; region?: string|null; city?: string|null; country_id?: number|null; interest?: string|null; following_tertiary_sub_interest?: string|null; following_secondary_sub_interest?: string|null; following_profession?: string|null; following_sub_interest?: string|null; following_country?: string|null; following_interest?: string|null; connectivity_ranking?: number|null; connectivity_percentage: number|null; relevancy_percentage?: number|null; relevancy_ranking: number|null; age?: string|null; groups?: string|null[]|null; rscore?: number|null; cscore?: number|null; weighted_connectivity?: number|null; likes_per_tweet?: number|null; tweets_per_month?: number|null; retweets_per_tweet?: number|null; active?: boolean|null; activetweeter?: boolean|null; tweet_id?: string|null; tweet_date?: string|null; facebook?: string|null; instagram?: string|null; linkedin?: string|null; youtube?: string|null; x?: number|null; y?: number|null; height?: number|null; membership?: number|null; size?: number|null }[] }[]|null
  insights_counts: any
  following_types: any[]|null
  is_super_insights: boolean
  influencer_groups: string[]
}
export type CreateCampaignPdfInput = {
  campaign_id: number
}
export type CreateCampaignPdf = {
  response: string
}
export type GetCampaignListQuery = {
  limit?: number
  offset?: number
  search?: string
  ordering?: string
  id?: string
  team?: string
  account_id?: string
  network?: string
  campaign_id?: string
  status?: string
  objective?: string
  completed?: string
  metrics?: string
}
export type CampaignListItem = {
  id: number
  adsets: number[]
  stats_first: CumulativeStats[]
  stats_last: CumulativeStats[]
  status: any
  stop_time: any
  lifetime_budget: any
  daily_budget: any
  currency: string
  first_creative_id: string
  has_pro_targeting: any
  via_extension: boolean
  created: string
  modified: string
  account_id: string
  network: 'facebook'|'twitter'|'linkedin'
  campaign_id: string
  name: string
  objective: string
  created_time: string
  start_time: string
  updated_time: string|null
  data: object|null
  completed: boolean
  synced: string|null
  funding_instrument: string|null
  io_header: string|null
  reach: number|null
  average_frequency: number|null
  budget_optimization: 'CAMPAIGN'|'LINE_ITEM'|null
  effective_status: string|null
  dbaccount: number
}
export type WriteTwitterCampaignInput = {
  name: string
  account_id: string
  status?: 'active'|'paused'|null
  objective: 'APP_ENGAGEMENTS'|'APP_INSTALLS'|'ENGAGEMENTS'|'FOLLOWERS'|'LEAD_GENERATION'|'PREROLL_VIEWS'|'REACH'|'VIDEO_VIEWS'|'WEBSITE_CLICKS'|'WEBSITE_CONVERSIONS'
  goal?: string|null
  is_conversions_objective?: boolean
  primary_web_event_tag?: string|null
  funding_instrument?: string|null
  lifetime_budget: number
  daily_budget?: number|null
  adsets: WriteTwitterAdsetInput[]
  app_store_identifier?: string|null
  categories?: string[]
  audience_expansion?: boolean
}
export type WriteTwitterCampaign = {
  name: string
  account_id: string
  status: 'active'|'paused'|null
  id: string
  objective: 'APP_ENGAGEMENTS'|'APP_INSTALLS'|'ENGAGEMENTS'|'FOLLOWERS'|'LEAD_GENERATION'|'PREROLL_VIEWS'|'REACH'|'VIDEO_VIEWS'|'WEBSITE_CLICKS'|'WEBSITE_CONVERSIONS'
  goal: string|null
  is_conversions_objective: boolean
  primary_web_event_tag: string|null
  funding_instrument: string|null
  daily_budget: number|null
  adsets: WriteTwitterAdset[]
  app_store_identifier: string|null
  categories: string[]
  audience_expansion: boolean
}
export type GetAdsQuery = GetCampaignListQuery
export type CampaignSummary = {
  name: string
  id: number
  network: 'facebook'|'twitter'|'linkedin'
  account_id: string
  currency: string
  objective: string
}
export type AdsetSummary = {
  name: string|null
  id: number
  status: 'active'|'paused'|'deleted'|'archived'|'finished'|null
  campaign: CampaignSummary
  adset_id: string
}
export type AdListItem = {
  id: number
  stats_first: CumulativeStats[]
  stats_last: CumulativeStats[]
  name: any
  adset: AdsetSummary
  created: string
  modified: string
  entity_type: string|null
  ad_id: string
  status: 'active'|'paused'|'deleted'|'archived'|'finished'|null
  creative_id: string|null
}
export type GetAdsetsQuery = GetCampaignListQuery
export type AdsetListItem = {
  id: number
  stats_first: CumulativeStats[]
  stats_last: CumulativeStats[]
  max_bid: any
  budget: any
  status: any
  lifetime_budget: any
  daily_budget: any
  conversion_window: any
  ads: number[]
  campaign: CampaignSummary
  created: string
  modified: string
  _campaign_id: string
  name: string|null
  adset_id: string
  start_time: string|null
  end_time: string|null
  budget_type: 'daily'|'lifetime'
  bid_amount: number
  is_autobid: boolean
  rule: string|null
  event_id: string|null
  pixel_id: string|null
  eventtype: string|null
  custom_event_type: string|null
  click_through_days: number
  view_through_days: number
  placement: object|null
  uses_sdb_audience: boolean|null
  uses_sdb_native_pack: boolean|null
  targeting_size: object|null
  _audience: number|null
}
export type GetReachQuery = {
  search?: string
  ordering?: string
  id?: string
  team?: string
  account_id?: string
  network?: string
  campaign_id?: string
  status?: string
  objective?: string
  completed?: string
  metrics?: string
}
export type ReachStats = {
  id: string
  total_audience_reach: number|null
  average_frequency: number|null
}
export type GetSegmentationsQuery = GetCampaignListQuery
export type AdSummary = {
  id: number
  status: 'active'|'paused'|'deleted'|'archived'|'finished'|null
  adset: AdsetSummary
  ad_id: string
}
export type SegmentationListItem = {
  id: number
  stats_first: CumulativeStats[]
  stats_last: CumulativeStats[]
  name: any
  ad: AdSummary
  created: string
  modified: string
  segmentation_type: 'AUDIENCES'|'SIMILAR_TO_FOLLOWERS_OF_USER'|'CUSTOM_AUDIENCE_EXPANDED'
  segment_value: string
  segment_name: string|null
}
export type GetStatstotalQuery = GetReachQuery
export type StatsTotal = {
  stats_first: CumulativeStats[]
  stats_last: CumulativeStats[]
  lifetime_budget: any
  daily_budget: any
}
export type DeleteCampaignInstanceQuery = GetCampaignListQuery
export type GetCampaignInstanceQuery = GetCampaignListQuery
export type Campaign = {
  id: number
  adsets: Adset[]
  stats_first: CumulativeStats[]
  stats_last: CumulativeStats[]
  status: any
  stop_time: any
  lifetime_budget: any
  daily_budget: any
  currency: string
  first_creative_id: string
  has_pro_targeting: any
  via_extension: boolean
  created: string
  modified: string
  account_id: string
  network: 'facebook'|'twitter'|'linkedin'
  campaign_id: string
  name: string
  objective: string
  created_time: string
  start_time: string
  updated_time: string|null
  data: object|null
  completed: boolean
  synced: string|null
  funding_instrument: string|null
  io_header: string|null
  reach: number|null
  average_frequency: number|null
  budget_optimization: 'CAMPAIGN'|'LINE_ITEM'|null
  effective_status: string|null
  dbaccount: number
}
export type PatchCampaignInstanceQuery = GetCampaignListQuery
export type PutCampaignInstanceQuery = GetCampaignListQuery
export type GetCommunitiesListQuery = {
  limit?: number
  offset?: number
  search?: string
  ordering?: string
  id?: string
}
export type AudienceCommunity = {
  id: number
  created: string
  modified: string
  input_query: string|null
  config: number|null
  count: number
  insights: number|null
  top_influencers: any[]
  community_rank: number
}
export type GetCommunitiesInstanceQuery = GetCommunitiesListQuery
export type GetCommunityListQuery = {
  search?: string
  type_code?: string
}
export type Community = {
  account_id: number
  connectivity: number
  relevancy: number
  profile_pic: string
  handle: string
  name: string
  followers: number
  friends: number
  bio: string
}
export type GetCommunityInstanceQuery = GetCommunityListQuery
export type ContentCategories = {
  name: string
  category_id: string
  iab_categories: any[]
  publishers_in_last_thirty_days: number
  videos_monetized_in_last_thirty_days: number
}
export type CancellationInput = {
  subscription: number
}
export type Cancellation = CancellationInput
export type CheckoutInput = {
  team: number
  plan: number
  audiences?: number
}
export type Checkout = {
  team: number
  plan: number
  audiences: number
  stripe_url: string
}
export type Plan = {
  id: number
  name: string
  price: number
  currency: 'USD'|'EUR'|'YEN'
  ad_budget: number
  audiences_included: number
  audience_price: any
  overages: any
  fixed_price: boolean
  deck: string|null
  description: string|null
  display_name: string|null
}
export type Subscription = {
  id: number
  team: number
  plan: Plan
  start: string
  active: boolean
  audiences: number
}
export type UpgradeInput = {
  subscription: number
  plan: number
  audiences: number
}
export type Upgrade = UpgradeInput
export type ExploreXInput = {
  countries?: string[]|null
  input_query: string|null
}
export type ExploreX = {
  id: number
  created: string
  modified: string
  status: any|null
  gender: { label: string; value: number; valueText: string }[]|null
  regions: { label: string; value: number; valueText: string }[]|null
  cities: { label: string; value: number; valueText: string; long: number|null; lat: number|null }[]|null
  specific_interests: { label: string; value: number; valueText: string }[]|null
  interests: { label: string; value: number; valueText: string }[]|null
  interest_fields: { label: string; value: number; valueText: string }[]|null
  professions: { label: string; value: number; valueText: string }[]|null
  profession_fields: { label: string; value: number; valueText: string }[]|null
  income_level: { label: string; value: number; valueText: string }[]|null
  education_level: { label: string; value: number; valueText: string }[]|null
  socioeconomic_status: { label: string; value: number; valueText: string }[]|null
  bio_words: { label: string; value: number; valueText: string }[]
  bio_hashtags: { label: string; value: number; valueText: string }[]|null
  bio_chunks: any
  tweet_words: { label: string; value: number; valueText: string }[]|null
  tweet_keywords: { label: string; value: number; valueText: string }[]|null
  mentioned_hashtags: { label: string; value: number; valueText: string }[]|null
  mentioned_accounts: { id: number; handle: string; bio: string|null; followers: number; name?: string; friends?: number; picture?: string|null; count: number }[]|null
  most_liked_accounts: { id: number; handle: string; bio: string|null; followers: number; name?: string; friends?: number; picture?: string|null; count: number }[]|null
  most_liked_tweets: { tweet_id: string; handle: string; tweet_text: string; likes: number; retweets: number; picture?: string|null; count: number }[]|null
  tweets_most_liked: { tweet_id: string; handle: string; tweet_text: string; likes: number; retweets: number; picture?: string|null; count: number }[]|null
  tweets_most_retweeted: { tweet_id: string; handle: string; tweet_text: string; likes: number; retweets: number; picture?: string|null; count: number }[]|null
  most_retweeted_accounts: { id: number; handle: string; bio: string|null; followers: number; name?: string; friends?: number; picture?: string|null; count: number }[]|null
  influencers: { id: number; handle: string; bio: string|null; followers: number; name: string; friends: number; picture: string|null; connectivity: number; relevancy: number; base_audience_count?: number|null; is_followed?: boolean|null; gender?: string; tertiary_sub_interest?: string|null; secondary_sub_interest?: string|null; profession?: string|null; sub_interest?: string|null; country?: string|null; country_name?: string|null; region?: string|null; city?: string|null; country_id?: number|null; interest?: string|null; following_tertiary_sub_interest?: string|null; following_secondary_sub_interest?: string|null; following_profession?: string|null; following_sub_interest?: string|null; following_country?: string|null; following_interest?: string|null; connectivity_ranking?: number|null; connectivity_percentage: number|null; relevancy_percentage?: number|null; relevancy_ranking: number|null; age?: string|null; groups?: string|null[]|null; rscore?: number|null; cscore?: number|null; weighted_connectivity?: number|null; likes_per_tweet?: number|null; tweets_per_month?: number|null; retweets_per_tweet?: number|null; active?: boolean|null; activetweeter?: boolean|null; tweet_id?: string|null; tweet_date?: string|null; facebook?: string|null; instagram?: string|null; linkedin?: string|null; youtube?: string|null; x?: number|null; y?: number|null; height?: number|null; membership?: number|null; size?: number|null }[]
  audience_estimation: { min: number; max: number; warning: string|null }|null
  targeting_package: { hashtags: { label: string; size: number|null; deselected: boolean|null }[]; handles: { picture: string|null; id: number; handle: string; bio: string|null; name: string; size: number|null; followers: number; relevancy: number; connectivity: number|null; country: string|null; deselected: boolean|null }[]; handles_ordered_by: string|null; keywords: { label: string; size: number|null; deselected: boolean|null }[]; conversation_topics: { label: string; size: number|null; targeting_value: string|null }[]; interests: { label: string; size: number|null; targeting_value: string|null }[]; movies_tv_shows: { label: string; size: number|null; targeting_value: string|null }[]; custom_audiences: string[]; custom_audiences_deselected: string[]|null; languages: { label: string; size: number|null; targeting_value: string|null }[]; network_operators: { label: string; size: number|null; targeting_value: string|null }[]; placements: string[]; gender: string; age: string; locations: string[] }|null
  targeting_package_size: { min: number; max: number }|null
  related_communities: null|string[]|null
  core_accounts: { id: number; handle: string; bio: string|null; followers: number; name: string; friends: number; picture?: string|null; likes_per_month?: number|null; tweets_per_month?: number|null; activity_score?: number|null; likes_count?: number|null; tweets_count?: number|null; site?: string|null; gender?: string|null; profession?: string|null; interest?: string|null; sub_interest?: string|null; country?: string|null; region?: string|null; city?: string|null; age?: string|null; secondary_sub_interest?: string|null; tertiary_sub_interest?: string|null }[]
  age: { label: string; value: number; valueText: string }[]|null
  sites: { label: string; value: number; valueText: string }[]|null
  accounts_count: { InitialSearch: number|null; JobTitles: number|null; Influencers: number|null; FollowersCount: number|null }|null
  suggestions: { query: string; metadata: { id: number; handle: string; bio: string|null; followers: number; name: string; friends: number; picture: string|null; connectivity: number; relevancy: number; base_audience_count?: number|null; is_followed?: boolean|null; gender?: string; tertiary_sub_interest?: string|null; secondary_sub_interest?: string|null; profession?: string|null; sub_interest?: string|null; country?: string|null; country_name?: string|null; region?: string|null; city?: string|null; country_id?: number|null; interest?: string|null; following_tertiary_sub_interest?: string|null; following_secondary_sub_interest?: string|null; following_profession?: string|null; following_sub_interest?: string|null; following_country?: string|null; following_interest?: string|null; connectivity_ranking?: number|null; connectivity_percentage: number|null; relevancy_percentage?: number|null; relevancy_ranking: number|null; age?: string|null; groups?: string|null[]|null; rscore?: number|null; cscore?: number|null; weighted_connectivity?: number|null; likes_per_tweet?: number|null; tweets_per_month?: number|null; retweets_per_tweet?: number|null; active?: boolean|null; activetweeter?: boolean|null; tweet_id?: string|null; tweet_date?: string|null; facebook?: string|null; instagram?: string|null; linkedin?: string|null; youtube?: string|null; x?: number|null; y?: number|null; height?: number|null; membership?: number|null; size?: number|null }[] }[]|null
  influencer_groups: string[]
}
export type GetStandaloneAdAccountListQuery = {
  account_id?: string
}
export type StandaloneAdAccount = {
  is_active: boolean
  account_id: string
  name: string
}
export type StandaloneAdAccountInput = {
  account_id: string
  name?: string
}
export type StandaloneAudienceAdAccountInput = {
  account_id: string
  name?: string|null
}
export type StandaloneAudienceAdAccount = {
  account_id: string
  name: string|null
}
export type GetStandaloneAdAccountInstanceQuery = GetStandaloneAdAccountListQuery
export type StandaloneDescriptionAnalyzerInput = {
  email?: string|null
  description: string
}
export type StandaloneDescriptionAnalyzer = {
  analyzed_keyword_groups: string[][]
  analyzed_handles: string[]
  analyzed_countries: string[]
  analyzed_location_states: string[]
  analyzed_gender: 1|2|12|null
  analyzed_age_range: string|null
}
export type StandalonePostsAnalyzerInput = {
  email: string
  input_handle?: string|null
  input_countries?: string|null
  input_start_date?: string|null
  input_end_date?: string|null
  status?: any|null
  gender?: { label: string; value: number; valueText: string }[]|null
  countries?: { label: string; value: number; valueText: string; alpha_2: string|null }[]|null
  regions?: { label: string; value: number; valueText: string }[]|null
  cities?: { label: string; value: number; valueText: string; long: number|null; lat: number|null }[]|null
  specific_interests?: { label: string; value: number; valueText: string }[]|null
  interests?: { label: string; value: number; valueText: string }[]|null
  interest_fields?: { label: string; value: number; valueText: string }[]|null
  professions?: { label: string; value: number; valueText: string }[]|null
  profession_fields?: { label: string; value: number; valueText: string }[]|null
  income_level?: { label: string; value: number; valueText: string }[]|null
  education_level?: { label: string; value: number; valueText: string }[]|null
  socioeconomic_status?: { label: string; value: number; valueText: string }[]|null
  bio_words?: { label: string; value: number; valueText: string }[]
  bio_hashtags?: { label: string; value: number; valueText: string }[]|null
  bio_chunks?: any
  tweet_words?: { label: string; value: number; valueText: string }[]|null
  tweet_keywords?: { label: string; value: number; valueText: string }[]|null
  mentioned_hashtags?: { label: string; value: number; valueText: string }[]|null
  mentioned_accounts?: { id: number; handle: string; bio: string|null; followers: number; name?: string; friends?: number; picture?: string|null; count: number }[]|null
  most_liked_accounts?: { id: number; handle: string; bio: string|null; followers: number; name?: string; friends?: number; picture?: string|null; count: number }[]|null
  most_liked_tweets?: { tweet_id: string; handle: string; tweet_text: string; likes: number; retweets: number; picture?: string|null; count: number }[]|null
  tweets_most_liked?: { tweet_id: string; handle: string; tweet_text: string; likes: number; retweets: number; picture?: string|null; count: number }[]|null
  tweets_most_retweeted?: { tweet_id: string; handle: string; tweet_text: string; likes: number; retweets: number; picture?: string|null; count: number }[]|null
  most_retweeted_accounts?: { id: number; handle: string; bio: string|null; followers: number; name?: string; friends?: number; picture?: string|null; count: number }[]|null
  influencers?: { id: number; handle: string; bio: string|null; followers: number; name: string; friends: number; picture: string|null; connectivity: number; relevancy: number; base_audience_count?: number|null; is_followed?: boolean|null; gender?: string; tertiary_sub_interest?: string|null; secondary_sub_interest?: string|null; profession?: string|null; sub_interest?: string|null; country?: string|null; country_name?: string|null; region?: string|null; city?: string|null; country_id?: number|null; interest?: string|null; following_tertiary_sub_interest?: string|null; following_secondary_sub_interest?: string|null; following_profession?: string|null; following_sub_interest?: string|null; following_country?: string|null; following_interest?: string|null; connectivity_ranking?: number|null; connectivity_percentage: number|null; relevancy_percentage?: number|null; relevancy_ranking: number|null; age?: string|null; groups?: string|null[]|null; rscore?: number|null; cscore?: number|null; weighted_connectivity?: number|null; likes_per_tweet?: number|null; tweets_per_month?: number|null; retweets_per_tweet?: number|null; active?: boolean|null; activetweeter?: boolean|null; tweet_id?: string|null; tweet_date?: string|null; facebook?: string|null; instagram?: string|null; linkedin?: string|null; youtube?: string|null; x?: number|null; y?: number|null; height?: number|null; membership?: number|null; size?: number|null }[]
  audience_estimation?: { min: number; max: number; warning: string|null }|null
  targeting_package?: { hashtags: { label: string; size: number|null; deselected: boolean|null }[]; handles: { picture: string|null; id: number; handle: string; bio: string|null; name: string; size: number|null; followers: number; relevancy: number; connectivity: number|null; country: string|null; deselected: boolean|null }[]; handles_ordered_by: string|null; keywords: { label: string; size: number|null; deselected: boolean|null }[]; conversation_topics: { label: string; size: number|null; targeting_value: string|null }[]; interests: { label: string; size: number|null; targeting_value: string|null }[]; movies_tv_shows: { label: string; size: number|null; targeting_value: string|null }[]; custom_audiences: string[]; custom_audiences_deselected: string[]|null; languages: { label: string; size: number|null; targeting_value: string|null }[]; network_operators: { label: string; size: number|null; targeting_value: string|null }[]; placements: string[]; gender: string; age: string; locations: string[] }|null
  targeting_package_size?: { min: number; max: number }|null
  related_communities?: null|string[]|null
  core_accounts?: { id: number; handle: string; bio: string|null; followers: number; name: string; friends: number; picture?: string|null; likes_per_month?: number|null; tweets_per_month?: number|null; activity_score?: number|null; likes_count?: number|null; tweets_count?: number|null; site?: string|null; gender?: string|null; profession?: string|null; interest?: string|null; sub_interest?: string|null; country?: string|null; region?: string|null; city?: string|null; age?: string|null; secondary_sub_interest?: string|null; tertiary_sub_interest?: string|null }[]
  age?: { label: string; value: number; valueText: string }[]|null
  sites?: { label: string; value: number; valueText: string }[]|null
  accounts_count?: { InitialSearch: number|null; JobTitles: number|null; Influencers: number|null; FollowersCount: number|null }|null
  suggestions?: { query: string; metadata: { id: number; handle: string; bio: string|null; followers: number; name: string; friends: number; picture: string|null; connectivity: number; relevancy: number; base_audience_count?: number|null; is_followed?: boolean|null; gender?: string; tertiary_sub_interest?: string|null; secondary_sub_interest?: string|null; profession?: string|null; sub_interest?: string|null; country?: string|null; country_name?: string|null; region?: string|null; city?: string|null; country_id?: number|null; interest?: string|null; following_tertiary_sub_interest?: string|null; following_secondary_sub_interest?: string|null; following_profession?: string|null; following_sub_interest?: string|null; following_country?: string|null; following_interest?: string|null; connectivity_ranking?: number|null; connectivity_percentage: number|null; relevancy_percentage?: number|null; relevancy_ranking: number|null; age?: string|null; groups?: string|null[]|null; rscore?: number|null; cscore?: number|null; weighted_connectivity?: number|null; likes_per_tweet?: number|null; tweets_per_month?: number|null; retweets_per_tweet?: number|null; active?: boolean|null; activetweeter?: boolean|null; tweet_id?: string|null; tweet_date?: string|null; facebook?: string|null; instagram?: string|null; linkedin?: string|null; youtube?: string|null; x?: number|null; y?: number|null; height?: number|null; membership?: number|null; size?: number|null }[] }[]|null
}
export type StandalonePostsAnalyzer = Insights
export type GetStandaloneAudienceCategoryListQuery = GetAudienceCategoryListQuery
export type GetStandaloneAudienceCategoryInstanceQuery = GetAudienceCategoryListQuery
export type GetStandaloneAudienceConfigListQuery = {
  limit?: number
  offset?: number
  search?: string
  categories?: string
  regions?: string
  classic_xpro_id?: string
  ad_account_id?: string
  token?: string
  list_type?: string
}
export type StandaloneAudienceConfig = {
  id: number
  created: string
  modified: string
  name: string
  description: string|null
  size: number
  approximate_count: string
  is_active: boolean
  categories: AudienceCategory[]
  regions: AudienceRegion[]
  plan_type: string
  top_influencers: any[]
  display_name: string
  audience_estimation: string
  native_estimation: string
  insights_id: number|null
  has_native_pack: boolean
  child_count: number
  parent_count: number
  status: 'Imported'|'Processing'|'Done'
}
export type StandaloneAudienceConfigInput = {
  name: string
  description?: string|null
  approximate_count: string
  is_active: boolean
  parameters: object
  insights_id: number|null
  ad_account_id: string
}
export type StandaloneShareViaAccountAudienceConfigInput = {
  ad_account_id: string
  audience_config_id?: string
}
export type StandaloneShareViaAccountAudienceConfig = {
}
export type StandaloneShareAudienceConfigInput = {
  audience_config_id: number
}
export type StandaloneShareAudienceConfig = {
  audiences_url: string
}
export type GetStandaloneAudienceConfigInstanceQuery = GetStandaloneAudienceConfigListQuery
export type RelatedAudienceConfig = {
  id: number
  parent: AudienceConfig
  child: AudienceConfig
}
export type StandaloneAudienceConfigDetail = {
  id: number
  created: string
  modified: string
  name: string
  description: string|null
  size: number
  approximate_count: string
  is_active: boolean
  categories: AudienceCategory[]
  regions: AudienceRegion[]
  plan_type: string
  top_influencers: any[]
  display_name: string
  audience_estimation: string
  native_estimation: string
  insights_id: number|null
  has_native_pack: boolean
  insights: Insights|null
  child: RelatedAudienceConfig[]
  parent: RelatedAudienceConfig[]
  status: any
}
export type PutStandaloneAudienceConfigInstanceQuery = GetStandaloneAudienceConfigListQuery
export type GetDownloadTargetingPackageQuery = {
  search?: string
  categories?: string
  regions?: string
  classic_xpro_id?: string
  ad_account_id?: string
  token?: string
  list_type?: string
}
export type StandaloneDownloadTargetingPackage = {
  url: string
}
export type StandaloneAudienceRequestInput = {
  ad_account_id?: string|null
  advertiser_name?: string|null
  audience_config_id?: number|null
  audience_size?: string|null
  audience_type?: 'custom'|'exclusion'|'abm'|'event'|null
  currency?: string|null
  description?: string|null
  email: string
  funding_source_id?: string|null
  geographic?: string|null
  google_drive_link?: string|null
  input_budget?: string|null
  input_end_date?: string|null
  input_start_date?: string|null
  io_signed?: boolean|null
  name?: string|null
  request_type?: 'native'|'audience'
  salesforce_id?: string|null
  advertiser_handle?: string|null
}
export type StandaloneAudienceRequest = {
  id: number
  asana_id: string|null
  native_pack_link: string|null
  ad_account_id: string|null
  advertiser_name: string|null
  audience_config_id: number|null
  audience_size: string|null
  audience_type: 'custom'|'exclusion'|'abm'|'event'|null
  currency: string|null
  description: string|null
  email: string
  funding_source_id: string|null
  geographic: string|null
  google_drive_link: string|null
  io_signed: boolean|null
  name: string|null
  request_type: 'native'|'audience'
  salesforce_id: string|null
  advertiser_handle: string|null
}
export type StandaloneFeedbackInput = {
  name: string
  email: string
  feedback: string
}
export type StandaloneFeedback = StandaloneFeedbackInput
export type StandaloneCheckAdAccountAccessInput = {
  ad_account_id: string
}
export type StandaloneCheckAdAccountAccess = {
  access: boolean
}
export type PrivateModelInput = {
  email: string
}
export type PrivateModel = StandaloneShareViaAccountAudienceConfig
export type StandaloneHandleInput = {
  handle: string
}
export type StandaloneHandle = {
  handle: string
  id: string
  name: string
  followers: number
  bio: string
  profile_pic_url: string
}
export type GetStandaloneAudienceUsedInAdsListQuery = {
  limit?: number
  offset?: number
  search?: string
  ad_account_id?: string
  token?: string
  state?: string
}
export type StandaloneAudienceUsedInAds = {
  id: number
  has_insights: boolean
  state: 'draft'|'launched'|'saved'|'applied'|'created'|'exclusion'|'job_title'
  name: string|null
  description: string|null
  ad_account_id: string
  ad_account_name: string
  audience_config_id: number|null
  audience_config_status: 'Imported'|'Processing'|'Done'
  countries: { label: string; value: number; valueText: string; alpha_2: string|null }[]|null
  created: string
  modified: string
  campaign_id: string|null
  campaign_name: string|null
  funding_source_id: string|null
  currency: string|null
  daily_budget: string|null
  total_budget: string|null
  start_date: string|null
  end_date: string|null
  audience_size: string|null
  data: object|null
  reviewed: boolean|null
}
export type StandaloneDetailAudienceUsedInAdsInput = {
  state?: 'draft'|'launched'|'saved'|'applied'|'created'|'exclusion'|'job_title'
  email?: string|null
  name?: string|null
  description?: string|null
  ad_account_id: string
  audience_config_id?: number|null
  audience_creator_query_id?: number|null
  countries?: { label: string; value: number; valueText: string; alpha_2: string|null }[]|null
  campaign_id?: string|null
  campaign_name?: string|null
  funding_source_id?: string|null
  currency?: string|null
  daily_budget?: string|null
  total_budget?: string|null
  start_date?: string|null
  end_date?: string|null
  audience_size?: string|null
  data?: object|null
  targeting_package_extra?: object
  exclusion_audience?: boolean
  reviewed?: boolean|null
  targeting_package: { hashtags: { label: string; size: number|null; deselected: boolean|null }[]; handles: { picture: string|null; id: number; handle: string; bio: string|null; name: string; size: number|null; followers: number; relevancy: number; connectivity: number|null; country: string|null; deselected: boolean|null }[]; handles_ordered_by: string|null; keywords: { label: string; size: number|null; deselected: boolean|null }[]; conversation_topics: { label: string; size: number|null; targeting_value: string|null }[]; interests: { label: string; size: number|null; targeting_value: string|null }[]; movies_tv_shows: { label: string; size: number|null; targeting_value: string|null }[]; custom_audiences: string[]; custom_audiences_deselected: string[]|null; languages: { label: string; size: number|null; targeting_value: string|null }[]; network_operators: { label: string; size: number|null; targeting_value: string|null }[]; placements: string[]; gender: string; age: string; locations: string[] }|null
}
export type StandaloneDetailAudienceUsedInAds = {
  id: number
  has_insights: boolean
  state: 'draft'|'launched'|'saved'|'applied'|'created'|'exclusion'|'job_title'
  name: string|null
  description: string|null
  ad_account_id: string
  ad_account_name: string
  audience_config_id: number|null
  audience_config_status: 'Imported'|'Processing'|'Done'
  countries: { label: string; value: number; valueText: string; alpha_2: string|null }[]|null
  created: string
  modified: string
  campaign_id: string|null
  campaign_name: string|null
  funding_source_id: string|null
  currency: string|null
  daily_budget: string|null
  total_budget: string|null
  start_date: string|null
  end_date: string|null
  audience_size: string|null
  data: object|null
  reviewed: boolean|null
  targeting_package: { hashtags: { label: string; size: number|null; deselected: boolean|null }[]; handles: { picture: string|null; id: number; handle: string; bio: string|null; name: string; size: number|null; followers: number; relevancy: number; connectivity: number|null; country: string|null; deselected: boolean|null }[]; handles_ordered_by: string|null; keywords: { label: string; size: number|null; deselected: boolean|null }[]; conversation_topics: { label: string; size: number|null; targeting_value: string|null }[]; interests: { label: string; size: number|null; targeting_value: string|null }[]; movies_tv_shows: { label: string; size: number|null; targeting_value: string|null }[]; custom_audiences: string[]; custom_audiences_deselected: string[]|null; languages: { label: string; size: number|null; targeting_value: string|null }[]; network_operators: { label: string; size: number|null; targeting_value: string|null }[]; placements: string[]; gender: string; age: string; locations: string[] }|null
}
export type GetAutopilotSuggestedBudgetQuery = {
  ad_account_id?: string
}
export type StandaloneAutopilotSuggestedBudget = {
  budget: number
  size: number|null
}
export type GetAutopilotUsageAllowedQuery = GetAutopilotSuggestedBudgetQuery
export type StandaloneAutopilotUsageAllowedAudienceUsedInAds = {
  allowed: boolean
  enterprise: boolean
}
export type GetAutopilotUsageQuery = GetAutopilotSuggestedBudgetQuery
export type StandaloneAutopilotUsageAudienceUsedInAds = {
  campaigns_launched: string
  campaigns_budget: string
}
export type StandaloneAudienceUsedInAdsGenerateSheetInput = {
  id: number
}
export type StandaloneAudienceUsedInAdsGenerateSheet = StandaloneDownloadTargetingPackage
export type StandaloneReorderHandlesAudienceUsedInAdsInput = {
  audience_used_in_ads_id: number
  order: 'balanced'|'narrow'|'broad'|'custom'
}
export type StandaloneReorderHandlesAudienceUsedInAds = {
  handles: { picture: string|null; id: number; handle: string; bio: string|null; name: string; size: number|null; followers: number; relevancy: number; connectivity: number|null; country: string|null; deselected: boolean|null }[]
}
export type StandaloneShareViaAccountAudienceUsedInAdsInput = {
  ad_account_id: string
  audience_used_in_ads_id?: string
}
export type StandaloneShareViaAccountAudienceUsedInAds = StandaloneShareViaAccountAudienceConfig
export type StandaloneShareAudienceUsedInAdsInput = {
  ad_account_id: string
  audience_used_in_ads_ids?: string
}
export type StandaloneShareAudienceUsedInAds = StandaloneShareAudienceConfig
export type StandaloneSharedAudienceUsedInAdsInput = {
  audience_used_in_ads_id?: string
}
export type StandaloneSharedAudienceUsedInAds = {
  ad_account_ids: string[]
}
export type GetVerifySignedQuery = {
  token?: string
}
export type DeleteStandaloneAudienceUsedInAdsInstanceQuery = GetStandaloneAudienceUsedInAdsListQuery
export type GetStandaloneAudienceUsedInAdsInstanceQuery = GetStandaloneAudienceUsedInAdsListQuery
export type PatchStandaloneAudienceUsedInAdsInstanceQuery = GetStandaloneAudienceUsedInAdsListQuery
export type PutStandaloneAudienceUsedInAdsInstanceQuery = GetStandaloneAudienceUsedInAdsListQuery
export type StandaloneAudienceUsedInAdsInput = {
  state?: 'draft'|'launched'|'saved'|'applied'|'created'|'exclusion'|'job_title'
  email?: string|null
  name?: string|null
  description?: string|null
  ad_account_id: string
  audience_config_id?: number|null
  audience_creator_query_id?: number|null
  countries?: { label: string; value: number; valueText: string; alpha_2: string|null }[]|null
  campaign_id?: string|null
  campaign_name?: string|null
  funding_source_id?: string|null
  currency?: string|null
  daily_budget?: string|null
  total_budget?: string|null
  start_date?: string|null
  end_date?: string|null
  audience_size?: string|null
  data?: object|null
  targeting_package_extra?: object
  exclusion_audience?: boolean
  reviewed?: boolean|null
}
export type PatchRestoreQuery = {
  search?: string
  ad_account_id?: string
  token?: string
  state?: string
}
export type CreateProTargetingPdfInput = {
  insights_id: number
}
export type CreateProTargetingPdf = CreateCampaignPdf
export type GetStandaloneAudienceListQuery = {
  limit?: number
  offset?: number
  search?: string
  ordering?: string
  id?: string
  ad_account?: string
  targetable?: string
}
export type GetStandaloneAudienceInstanceQuery = GetStandaloneAudienceListQuery
export type GetStandAloneCampaignOptionListQuery = GetAutopilotSuggestedBudgetQuery
export type StandAloneCampaignOption = {
  campaign_id: string
  name: string
}
export type GetStandAloneCampaignOptionInstanceQuery = GetAutopilotSuggestedBudgetQuery
export type GetStandaloneCheckAgencyAdAccountListQuery = GetAutopilotSuggestedBudgetQuery
export type StandaloneCheckAgencyAdAccount = {
  ad_account_ids: string[]
  agency: string
}
export type GetStandaloneCheckAgencyAdAccountInstanceQuery = GetAutopilotSuggestedBudgetQuery
export type StandaloneCheckCustomAudiencesInput = {
  ad_account_id: string
  audience_config_id?: number
  audience_used_in_ads_id?: number
}
export type StandaloneCustomAudienceStatus = {
  name: string
  audience_id: string
  targetable: boolean
  reasons_not_targetable: string[]
}
export type StandaloneCheckCustomAudiences = {
  custom_audiences: StandaloneCustomAudienceStatus[]
}
export type StandaloneConversationTopic = {
  label: string
}
export type StandaloneSearchConversationTopicsInput = {
  keywords: string
}
export type StandaloneSearchConversationTopics = {
  conversation_topics: StandaloneConversationTopic[]
}
export type StandAloneCreateExclusionAudienceInput = {
  name: string
  email: string
  use_in_all_campaigns: boolean
  ad_account_id: string
  campaign_ids: string[]
}
export type StandAloneCreateExclusionAudience = {
  name: string
  email: string
  audience_id_link: string
}
export type DeepThoughtFollowerGrowth = {
  refdate: string
  followers: number
}
export type DeepThoughtAccountStats = {
  follower_growth: DeepThoughtFollowerGrowth[]
}
export type DeepThoughtEnrichmentAccountsInput = {
  user_id: number
  classifier_ids?: number[]|null
}
export type DeepThoughtAccount = {
  accountid: string
  handle: string
  name: string|null
  bio: string
  followers: number
  friends: number
  pic: string
  verified: boolean
  verified_count: number|null
  verified_count_rank: number|null
  token: string|null
  bot: boolean|null
  active: boolean|null
  gender: string|null
}
export type DeepThoughtEnrichmentClassifierAccounts = {
  classifer_id: number
  accounts: DeepThoughtAccount[]
}
export type DeepThoughtEnrichmentAccounts = {
  enriched_classifier_accounts: DeepThoughtEnrichmentClassifierAccounts[]
}
export type DeepThoughtEnrichmentsInput = {
  handles: string[]
}
export type DeepThoughtEnrichmentTopic = {
  id: number
  topic: string
  score: number
}
export type DeepThoughtEnrichment = {
  parent: string
  children: DeepThoughtEnrichmentTopic[]
}
export type DeepThoughtEnrichedAccount = {
  account: DeepThoughtAccount
  enrichments: DeepThoughtEnrichment[]
}
export type DeepThoughtEnrichments = {
  enriched_accounts: DeepThoughtEnrichedAccount[]
}
export type DeepThoughtFollowerOverlap = {
  accounts: DeepThoughtAccount[]
}
export type GetFollowersQuery = {
  limit?: number
  offset?: number
  verified?: string
  active?: string
  handle?: string
  follower_gte?: string
  follower_lte?: string
  order_by?: string
}
export type GetFollowingQuery = GetFollowersQuery
export type DeepThoughtPostAccount = {
  accountid: string
  pic: string
  handle: string
  name: string
  verified: boolean
}
export type DeepThoughtPostTweet = {
  id: string
  text: string
  like_count: number
  retweet_count: number
  impression_count: number|null
  reply_count: number|null
  quote_count: number|null
  created_at: string
  verified_like_count: number|null
}
export type DeepThoughtPost = {
  account: DeepThoughtPostAccount
  tweet: DeepThoughtPostTweet
}
export type DeepThoughtPostCounts = {
  total: number|null
  like_count: number|null
  retweet_count: number|null
  reply_count: number|null
  quote_count: number|null
}
export type DeepThoughtViewCounts = {
  retweets: number|null
  replies: number|null
  quotes: number|null
  posts: number|null
  percentage: number|null
}
export type DeepThoughtVerifiedEngagementCounts = {
  verified_like_count: number|null
  verified_retweet_count: number|null
  verified_quote_count: number|null
  verified_reply_count: number|null
  verified_engagement_count: number|null
}
export type DeepThoughtPostsAndLikes = {
  posts: DeepThoughtPost[]
  likers: DeepThoughtAccount[]
  post_counts: DeepThoughtPostCounts
  view_counts: DeepThoughtViewCounts
  verified_engagement_counts: DeepThoughtVerifiedEngagementCounts
}
export type DeepThoughtAccountRecentlyJoined = {
  recently_joined: boolean
}
export type DeepThoughtQuestions = {
  questions: string[]
}
export type DeepThoughtTalkToXInput = {
  query: string
}
export type DeepThoughtTalkToX = {
  accounts: DeepThoughtAccount[]
  followers: DeepThoughtAccount[]
  friends: DeepThoughtAccount[]
  posts: DeepThoughtPost[]
  question: string|null
  information_type: string
  title: string
  percentage_active: string
  gender_split: any|null
  community_size: string
  enrichments: DeepThoughtEnrichment[]
}
export type DeepThoughtNativePackGeneratorInput = {
  input_keyword_groups?: string[][]|null
  input_handles?: string[]|null
  input_countries?: string[]|null
  input_genders?: ('any'|'male'|'female')[]|null
  input_audience_selectivity?: 'balanced'|'narrow'|'broad'|null
  input_exclude_keywords?: string[]|null
  input_location_states?: string[]|null
  input_budget?: 500|1000|2500|5000|10000|20000|50000|100000|250000|500000|750000|1000000|null
  input_size?: 25000|50000|100000|150000|300000|600000|1450000|3000000|7300000|14600000|21900000|29200000|null
  description?: string|null
  email?: string|null
  ad_account_id?: string|null
  plan_type?: string|null
}
export type DeepThoughtNativePackGenerator = {
  id: number
  created: string
  modified: string
  status: any|null
  gender: { label: string; value: number; valueText: string }[]|null
  countries: { label: string; value: number; valueText: string; alpha_2: string|null }[]|null
  regions: { label: string; value: number; valueText: string }[]|null
  cities: { label: string; value: number; valueText: string; long: number|null; lat: number|null }[]|null
  specific_interests: { label: string; value: number; valueText: string }[]|null
  interests: { label: string; value: number; valueText: string }[]|null
  interest_fields: { label: string; value: number; valueText: string }[]|null
  professions: { label: string; value: number; valueText: string }[]|null
  profession_fields: { label: string; value: number; valueText: string }[]|null
  income_level: { label: string; value: number; valueText: string }[]|null
  education_level: { label: string; value: number; valueText: string }[]|null
  socioeconomic_status: { label: string; value: number; valueText: string }[]|null
  bio_words: { label: string; value: number; valueText: string }[]
  bio_hashtags: { label: string; value: number; valueText: string }[]|null
  bio_chunks: any
  tweet_words: { label: string; value: number; valueText: string }[]|null
  tweet_keywords: { label: string; value: number; valueText: string }[]|null
  mentioned_hashtags: { label: string; value: number; valueText: string }[]|null
  mentioned_accounts: { id: number; handle: string; bio: string|null; followers: number; name?: string; friends?: number; picture?: string|null; count: number }[]|null
  most_liked_accounts: { id: number; handle: string; bio: string|null; followers: number; name?: string; friends?: number; picture?: string|null; count: number }[]|null
  most_liked_tweets: { tweet_id: string; handle: string; tweet_text: string; likes: number; retweets: number; picture?: string|null; count: number }[]|null
  tweets_most_liked: { tweet_id: string; handle: string; tweet_text: string; likes: number; retweets: number; picture?: string|null; count: number }[]|null
  tweets_most_retweeted: { tweet_id: string; handle: string; tweet_text: string; likes: number; retweets: number; picture?: string|null; count: number }[]|null
  most_retweeted_accounts: { id: number; handle: string; bio: string|null; followers: number; name?: string; friends?: number; picture?: string|null; count: number }[]|null
  influencers: { id: number; handle: string; bio: string|null; followers: number; name: string; friends: number; picture: string|null; connectivity: number; relevancy: number; base_audience_count?: number|null; is_followed?: boolean|null; gender?: string; tertiary_sub_interest?: string|null; secondary_sub_interest?: string|null; profession?: string|null; sub_interest?: string|null; country?: string|null; country_name?: string|null; region?: string|null; city?: string|null; country_id?: number|null; interest?: string|null; following_tertiary_sub_interest?: string|null; following_secondary_sub_interest?: string|null; following_profession?: string|null; following_sub_interest?: string|null; following_country?: string|null; following_interest?: string|null; connectivity_ranking?: number|null; connectivity_percentage: number|null; relevancy_percentage?: number|null; relevancy_ranking: number|null; age?: string|null; groups?: string|null[]|null; rscore?: number|null; cscore?: number|null; weighted_connectivity?: number|null; likes_per_tweet?: number|null; tweets_per_month?: number|null; retweets_per_tweet?: number|null; active?: boolean|null; activetweeter?: boolean|null; tweet_id?: string|null; tweet_date?: string|null; facebook?: string|null; instagram?: string|null; linkedin?: string|null; youtube?: string|null; x?: number|null; y?: number|null; height?: number|null; membership?: number|null; size?: number|null }[]
  audience_estimation: { min: number; max: number; warning: string|null }|null
  targeting_package: { hashtags: { label: string; size: number|null; deselected: boolean|null }[]; handles: { picture: string|null; id: number; handle: string; bio: string|null; name: string; size: number|null; followers: number; relevancy: number; connectivity: number|null; country: string|null; deselected: boolean|null }[]; handles_ordered_by: string|null; keywords: { label: string; size: number|null; deselected: boolean|null }[]; conversation_topics: { label: string; size: number|null; targeting_value: string|null }[]; interests: { label: string; size: number|null; targeting_value: string|null }[]; movies_tv_shows: { label: string; size: number|null; targeting_value: string|null }[]; custom_audiences: string[]; custom_audiences_deselected: string[]|null; languages: { label: string; size: number|null; targeting_value: string|null }[]; network_operators: { label: string; size: number|null; targeting_value: string|null }[]; placements: string[]; gender: string; age: string; locations: string[] }|null
  targeting_package_size: { min: number; max: number }|null
  related_communities: null|string[]|null
  core_accounts: { id: number; handle: string; bio: string|null; followers: number; name: string; friends: number; picture?: string|null; likes_per_month?: number|null; tweets_per_month?: number|null; activity_score?: number|null; likes_count?: number|null; tweets_count?: number|null; site?: string|null; gender?: string|null; profession?: string|null; interest?: string|null; sub_interest?: string|null; country?: string|null; region?: string|null; city?: string|null; age?: string|null; secondary_sub_interest?: string|null; tertiary_sub_interest?: string|null }[]
  age: { label: string; value: number; valueText: string }[]|null
  sites: { label: string; value: number; valueText: string }[]|null
  accounts_count: { InitialSearch: number|null; JobTitles: number|null; Influencers: number|null; FollowersCount: number|null }|null
  suggestions: { query: string; metadata: { id: number; handle: string; bio: string|null; followers: number; name: string; friends: number; picture: string|null; connectivity: number; relevancy: number; base_audience_count?: number|null; is_followed?: boolean|null; gender?: string; tertiary_sub_interest?: string|null; secondary_sub_interest?: string|null; profession?: string|null; sub_interest?: string|null; country?: string|null; country_name?: string|null; region?: string|null; city?: string|null; country_id?: number|null; interest?: string|null; following_tertiary_sub_interest?: string|null; following_secondary_sub_interest?: string|null; following_profession?: string|null; following_sub_interest?: string|null; following_country?: string|null; following_interest?: string|null; connectivity_ranking?: number|null; connectivity_percentage: number|null; relevancy_percentage?: number|null; relevancy_ranking: number|null; age?: string|null; groups?: string|null[]|null; rscore?: number|null; cscore?: number|null; weighted_connectivity?: number|null; likes_per_tweet?: number|null; tweets_per_month?: number|null; retweets_per_tweet?: number|null; active?: boolean|null; activetweeter?: boolean|null; tweet_id?: string|null; tweet_date?: string|null; facebook?: string|null; instagram?: string|null; linkedin?: string|null; youtube?: string|null; x?: number|null; y?: number|null; height?: number|null; membership?: number|null; size?: number|null }[] }[]|null
  audience_creator_query_id: number
  analyzed_keyword_groups: string[][]
  analyzed_handles: string[]
  analyzed_countries: string[]
  analyzed_location_states: string[]
  analyzed_gender: 1|2|12|null
  analyzed_age_range: string|null
  analyzed_audience_selectivity: 'balanced'|'narrow'|'broad'
  plan_type: string|null
}
export type GetPostEngagementsQuery = {
  verified?: string
  active?: string
  handle?: string
  follower_gte?: string
  follower_lte?: string
  order_by?: string
}
export type DeepThoughtPostEngagement = {
  post: DeepThoughtPost
  likers: DeepThoughtAccount[]
  retweeters: DeepThoughtAccount[]
}
export type Blob = {
  file: any
  preview_file: any|null
  name: string
  description: string|null
  bucket: 'dashboard-storage-msdb'|'xprotargeting-assets'|'xprotargeting-assets-private'
}
export type Event = {
  id: number
  name: string
  title: string
  description: string
  slides_url: string
  icon: Blob|null
  header: Blob
  slides: Blob
  onepager: Blob
  targeting_keywords: Blob
  dashboard: Blob|null
}
export type StandaloneNativePackGeneratorInput = DeepThoughtNativePackGeneratorInput
export type StandaloneNativePackGenerator = DeepThoughtNativePackGenerator
export type StandaloneInterest = StandaloneConversationTopic
export type StandaloneSearchInterestsInput = StandaloneSearchConversationTopicsInput
export type StandaloneSearchInterests = {
  interests: StandaloneInterest[]
}
export type StandaloneJobTitleTargetingInput = {
  email?: string|null
  ad_account_id: string
  campaign_name?: string|null
  campaign_id?: string|null
  job_titles: string[]
  countries: string[]
}
export type StandaloneJobTitleTargeting = {
  custom_audience_names: string[]
}
export type StandaloneAutopilotKeywordSuggestionUsageInput = {
  keyword_groups?: string[][]|null
  exclude_keywords?: string[]|null
  countries?: string[]|null
  handles?: string[]|null
  suggestions_added?: string[]|null
  suggestions?: string[]|null
  ad_account_id?: string|null
  description?: string|null
}
export type StandaloneAutopilotKeywordSuggestionUsageManyInput = {
  usages: StandaloneAutopilotKeywordSuggestionUsageInput[]
}
export type StandaloneAutopilotKeywordSuggestionUsageMany = {
  success: boolean
}
export type StandaloneLanguage = StandaloneConversationTopic
export type GetStandaloneTargetingLocationsListQuery = {
  country_code?: string
}
export type StandaloneTargetingLocation = StandaloneConversationTopic
export type StandaloneSearchTargetingLocationsInput = {
  country_code: string
  keywords: string
}
export type StandaloneSearchTargetingLocations = {
  locations: StandaloneTargetingLocation[]
}
export type MarketingMaterial = {
  id: number
  name: string
  title: string
  document_type: 'one-pager'|'slides'|'faq'|'case-study'
  file: Blob
}
export type MarketingMaterialInput = PrivateModelInput
export type StandaloneMoviesTvShows = StandaloneConversationTopic
export type StandaloneSearchMoviesTvShowsInput = StandaloneSearchConversationTopicsInput
export type StandaloneSearchMoviesTvShows = {
  movies_tv_shows: StandaloneMoviesTvShows[]
}
export type StandaloneNetworkOperator = StandaloneConversationTopic
export type StandaloneSearchNetworkOperatorsInput = StandaloneSearchConversationTopicsInput
export type StandaloneSearchNetworkOperators = {
  network_operators: StandaloneNetworkOperator[]
}
export type GetStandaloneRecentlyCreatedAudiencesListQuery = GetAutopilotSuggestedBudgetQuery
export type StandaloneRecentlyCreatedAudienceConfig = {
  id: number
  created: string
}
export type StandaloneRecentlyCreatedAudienceUsedInAds = {
  id: number
  audience_config_status: 'Imported'|'Processing'|'Done'
  created: string
}
export type StandaloneRecentlyCreatedAudiences = {
  audience_configs: StandaloneRecentlyCreatedAudienceConfig[]
  audience_used_in_ads: StandaloneRecentlyCreatedAudienceUsedInAds[]
}
export type GetStandaloneRecentlyCreatedAudiencesInstanceQuery = GetAutopilotSuggestedBudgetQuery
export type StandaloneRelatedKeywordsInput = {
  input_keywords: string[]
  exclude_keywords?: string[]|null
}
export type StandaloneRelatedKeywords = {
  related_keywords: string[]
}
export type StandAloneUploadAudienceInput = {
  ad_account_id: string
  config_id: number
}
export type StandAloneUploadAudience = {
  audience_used_in_ads_id: number
}
export type GeoLocation = {
  country: string
}
export type HelperDocuments = {
  id: number
  description: string|null
  filename: any
  label: string|null
  created_at: string
  updated_at: string
}
export type GetDownloadQuery = {
  id?: string
  name?: string
}
export type HelperDocumentsDownload = StandaloneDownloadTargetingPackage
export type GetIconoGraphyListQuery = {
  search?: string
  id?: string
  child?: string
  parent?: string
}
export type Icon = {
  id: number
  name: string
  icon_image: any|null
  icon_type: 'group'
  group_id: number|null
  neighbours: number[]
  type_code: number|null
}
export type IconNeighbours = {
  id: number
  name: string
  icon_image: any|null
  icon_type: 'group'
  group_id: number|null
  neighbours: Icon[]
  type_code: number|null
}
export type Iconography = {
  id: number
  child: IconNeighbours|null
  parent: IconNeighbours
  order: number
}
export type GetIconoGraphyInstanceQuery = GetIconoGraphyListQuery
export type GetInsightsInfluencerListQuery = {
  limit?: number
  offset?: number
  search?: string
  ordering?: string
  account_id?: string
  handle?: string
}
export type InsightsInfluencer = {
  id: number
  community_name: string|null
  created: string
  modified: string
  account_id: number
  handle: string
  influencer_data: object|null
  rank: number
  insights: number
}
export type GetInsightsInfluencerInstanceQuery = GetInsightsInfluencerListQuery
export type CreateInsightsPdfInput = CreateProTargetingPdfInput
export type CreateInsightsPdf = CreateCampaignPdf
export type JobTitle = {
  id: number
  name: string
}
export type MetricInfo = {
  key: string
  label: string
  description: string
  group: 'delivery'|'engagement'|'spend'|'video'|'website'|'conversions'|'other'
  networks: ('twitter'|'facebook'|'linkedin')[]
  objectives: string[]
  is_super_metric: boolean
  is_featured: boolean
  unit: 'none'|'percent'|'currency'
  aggregate: 'total'|'average'
  abm_phase: 'phase_1_video_views'|'phase_2_engagements'|'phase_3_traffic'|null
}
export type E2E = {
  e2estatus: string
}
export type TargetingCountries = {
  key: string
  name: string
}
export type TargetingInterests = TargetingCountries
export type TargetingLocations = {
  key: string
  name: string
  country_code: string
}
export type GetUserTeamListQuery = {
  ordering?: string
}
export type TeamInput = {
  name: string
  logo?: string|null
  website?: string|null
  invoice_name?: string
  vat_number?: string|null
  address?: string|null
  zip_code?: string|null
  city?: string|null
  country?: string|null
  phone?: string|null
  invoice_email?: string|null
}
export type Team = {
  id: number
  slug: string|null
  validate_email_domain: boolean
  feature_campaigns: boolean
  feature_superinsights: boolean
  region: number|null
  member_count: number
  subscriptions: Subscription[]
  accepted_terms_and_conditions: string|null
  name: string
  logo: string|null
  website: string|null
  invoice_name: string
  vat_number: string|null
  address: string|null
  zip_code: string|null
  city: string|null
  country: string|null
  phone: string|null
  invoice_email: string|null
}
export type GetUserTeamInstanceQuery = GetUserTeamListQuery
export type PatchUserTeamInstanceQuery = GetUserTeamListQuery
export type PutUserTeamInstanceQuery = GetUserTeamListQuery
export type AdAccount = {
  name: string|null
  network: 'facebook'|'twitter'|'linkedin'
  timezone: string|null
  default_page_id: string|null
  funding_instruments: any
  timezone_offset_hours_utc: any
  countries: any[]
  id: string
  default_funding_instrument: string|null
  pixels: object|null
  default_pixel_id: string|null
}
export type TeamInvite = {
  id: number
  team_name: string
  invite_url: string
  invited_email: string
  level: 'admin'|'member'
  message: string|null
  accepted_at: string|null
  updated_at: string
  created_at: string
  team: number
  user: number
  invited_user: number|null
}
export type TeamInviteInput = {
  invited_email: string
  level?: 'admin'|'member'
  message?: string|null
}
export type InviteAcceptInput = {
  code: string
}
export type InviteAccept = InviteAcceptInput
export type TeamUser = {
  id: number
  username: string
  first_name: string
  last_name: string
  email: string
  date_joined: string
  last_login: string|null
}
export type TeamMember = {
  user: TeamUser
  team: number
  level: 'admin'|'member'
  updated_at: string
  created_at: string
  accounts: string[]
}
export type UploadedAudiencesInput = {
  ad_account: string
  team: string
  configs_to_upload: number[]
}
export type UploadedAudiences = UploadedAudiencesInput
export type UserAdAccount = {
  name: string|null
  network: 'facebook'|'twitter'|'linkedin'
  id: string
}
export type FollowUnfollowInput = {
  insights_id: number
  account_ids: number[]
  is_followed?: boolean|null
}
export type FollowUnfollow = {
  is_followed: boolean|null
}
export type _GrantedAccount = {
  id: number
  account_id: string
  name: string|null
  network: 'facebook'|'twitter'|'linkedin'
}
export type GrantedAccount = {
  id: number
  account: _GrantedAccount
  updated_at: string
  member: number
}
export type _GrantedAccountInput = {
  account_id: string
  name?: string|null
  network: 'facebook'|'twitter'|'linkedin'
}
export type GrantedAccountInput = {
  account: _GrantedAccountInput
  member: number
}
export type TwitterList = {
  id: string|null
  name: string
  description: string|null
  private: boolean
}
export type TwitterListInput = {
  id?: string|null
  name: string
  description: string|null
  private?: boolean
}
export type TwitterListsMembersInput = {
  id?: string|null
  intent: 'create'|'destroy'
  members: number[]
}
export type TwitterListsMembers = {
  id: string|null
  intent: 'create'|'destroy'
  members: number[]
  message: string
}
export type CreateMagicLoginLinkInput = PrivateModelInput
export type CreateMagicLoginLink = {
  email: string
  magic_code: string
}
export type UseMagicLoginLinkInput = {
  magic_code: string
  confirm_code: string
}
export type UseMagicLoginLink = StandaloneShareViaAccountAudienceConfig
export type SocialAccount = {
  id: number
  uid: string
  provider: string
  name: any
  last_login: string
  date_joined: string
}
export type Membership = {
  id: number
  level: 'admin'|'member'
  team: number
  team_name: string
}
export type Group = {
  name: string
}
export type User = {
  id: number
  email: string
  first_name: string
  last_name: string
  social_accounts: SocialAccount[]
  memberships: Membership[]
  groups: Group[]
  token: string
  onboarding_completed_at: string|null
  language: 'en'|'ja'|null
  team_invite: any
  company: string|null
  job_title: string|null
}
export type SocialAccountInput = {
  uid: string
  provider: string
}
export type MembershipInput = {
  level: 'admin'|'member'
  team: number
  team_name: string
}
export type GroupInput = Group
export type UserInput = {
  email?: string
  first_name?: string
  last_name?: string
  social_accounts?: SocialAccountInput[]
  memberships?: MembershipInput[]
  groups?: GroupInput[]
  onboarding_completed_at?: string|null
  language?: 'en'|'ja'|null
  company?: string|null
  job_title?: string|null
}
export type WebEvents = {
  name: string
  event_id: string
  tag_id: string
  status: string
  type: string
}
export type SocialAccount2 = {
  id: number
  provider: string
  uid: string
  last_login: string
  date_joined: string
}
export type SocialConnectInput = {
  access_token?: string
  code?: string
  id_token?: string
}
export type SocialConnect = {
  access_token: string
  code: string
  id_token: string
}
export type TwitterLoginInput = {
  access_token: string
  token_secret: string
}
export type TwitterLogin = TwitterLoginInput
export type TwitterAccessToken = {
  access_token: string
  secret_token: string
  csrf_token: string
}
export type TwitterConnectInput = TwitterLoginInput
export type TwitterConnect = TwitterLoginInput
export type TwitterRequestToken = {
  oauth_token: string
}
export type MyLoginInput = SocialConnectInput
export type MyLogin = SocialConnect
export type TwitterAuthParams = {
  code_challenge: string
  code_challenge_method: string
  csrf_token: string
}
export const schema = {
  AccountApps: {
    list: {
      /** Account apps list */
      path: '/api/account-apps/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as AccountApps[],
    },
  },
  AdCreatives: {
    list: {
      path: '/api/ad-creatives/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as AdCreative[],
    },
    create: {
      path: '/api/ad-creatives/',
      method: 'POST',
      paramsType: {} as {
        body: AdCreativeInput
      },
      responseType: {} as AdCreative,
    },
    destroy: {
      path: '/api/ad-creatives/{pk}/',
      method: 'DELETE',
      paramsType: {} as {
        path: {
          pk: string|number
        }
      },
      responseType: {} as {},
    },
    retrieve: {
      path: '/api/ad-creatives/{pk}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          pk: string|number
        }
      },
      responseType: {} as AdCreative,
    },
    partial_update: {
      path: '/api/ad-creatives/{pk}/',
      method: 'PATCH',
      paramsType: {} as {
        body: Partial<AdCreativeInput>
        path: {
          pk: string|number
        }
      },
      responseType: {} as AdCreative,
    },
    update: {
      path: '/api/ad-creatives/{pk}/',
      method: 'PUT',
      paramsType: {} as {
        body: AdCreativeInput
        path: {
          pk: string|number
        }
      },
      responseType: {} as AdCreative,
    },
  },
  AdMedia: {
    list: {
      path: '/api/ad-media/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as AdMedia[],
    },
    create: {
      path: '/api/ad-media/',
      method: 'POST',
      paramsType: {} as {
        body: AdMediaInput
      },
      responseType: {} as AdMedia,
    },
    destroy: {
      path: '/api/ad-media/{pk}/',
      method: 'DELETE',
      paramsType: {} as {
        path: {
          pk: string|number
        }
      },
      responseType: {} as {},
    },
  },
  Ad: {
    destroy: {
      path: '/api/ads/{pk}/',
      method: 'DELETE',
      paramsType: {} as {
        path: {
          pk: string|number
        }
      },
      responseType: {} as {},
    },
    toggle: {
      path: '/api/ads/{pk}/toggle/',
      method: 'POST',
      paramsType: {} as {
        body: {}
        path: {
          pk: string|number
        }
      },
      responseType: {} as {},
    },
  },
  AdSet: {
    list: {
      path: '/api/adsets/',
      method: 'GET',
      paramsType: {} as {
        query?: GetAdSetListQuery
      },
      responseType: {} as {
        count: number
        next: string
        previous: string
        results: Adset[]
      },
    },
    create: {
      path: '/api/adsets/',
      method: 'POST',
      paramsType: {} as {
        body: AdsetInput
      },
      responseType: {} as Adset,
    },
    destroy: {
      path: '/api/adsets/{pk}/',
      method: 'DELETE',
      paramsType: {} as {
        path: {
          pk: string|number
        }
        query?: DeleteAdSetInstanceQuery
      },
      responseType: {} as {},
    },
    retrieve: {
      path: '/api/adsets/{pk}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          pk: string|number
        }
        query?: GetAdSetInstanceQuery
      },
      responseType: {} as Adset,
    },
    partial_update: {
      path: '/api/adsets/{pk}/',
      method: 'PATCH',
      paramsType: {} as {
        body: Partial<WriteTwitterAdsetInput>
        path: {
          pk: string|number
        }
        query?: PatchAdSetInstanceQuery
      },
      responseType: {} as WriteTwitterAdset,
    },
    update: {
      path: '/api/adsets/{pk}/',
      method: 'PUT',
      paramsType: {} as {
        body: WriteTwitterAdsetInput
        path: {
          pk: string|number
        }
        query?: PutAdSetInstanceQuery
      },
      responseType: {} as WriteTwitterAdset,
    },
  },
  AdvertiserBusinessCategories: {
    list: {
      /** Account advertiser business categories list */
      path: '/api/advertiser-categories/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as AdvertiserBusinessCategories[],
    },
  },
  FileUploadCompleteHandler: {
    post: {
      path: '/api/api/files/complete/',
      method: 'POST',
      paramsType: {} as {
        body: {}
      },
      responseType: {} as {},
    },
  },
  FilePolicyAPI: {
    post: {
      /** This view is to get the AWS Upload Policy for our s3 bucket.
      What we do here is first create a FileItem object instance in our
      Django backend. This is to include the FileItem instance in the path
      we will use within our bucket as you'll see below. */
      path: '/api/api/files/policy/',
      method: 'POST',
      paramsType: {} as {
        body: {}
      },
      responseType: {} as {},
    },
  },
  AppEvents: {
    list: {
      /** Account app events list */
      path: '/api/app-events/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as AppEvents[],
    },
  },
  AudienceCategory: {
    list: {
      path: '/api/audience-categories/',
      method: 'GET',
      paramsType: {} as {
        query?: GetAudienceCategoryListQuery
      },
      responseType: {} as AudienceCategory[],
    },
    retrieve: {
      path: '/api/audience-categories/{pk}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          pk: string|number
        }
        query?: GetAudienceCategoryInstanceQuery
      },
      responseType: {} as AudienceCategory,
    },
  },
  AudienceConfig: {
    list: {
      path: '/api/audience-configs/',
      method: 'GET',
      paramsType: {} as {
        query?: GetAudienceConfigListQuery
      },
      responseType: {} as {
        count: number
        next: string
        previous: string
        results: AudienceConfig[]
      },
    },
    create: {
      path: '/api/audience-configs/',
      method: 'POST',
      paramsType: {} as {
        body: AudienceConfigInput
      },
      responseType: {} as AudienceConfig,
    },
    autocomplete: {
      path: '/api/audience-configs/autocomplete/',
      method: 'POST',
      paramsType: {} as {
        body: AutoCompleteInput
      },
      responseType: {} as AutoComplete,
    },
    insights: {
      path: '/api/audience-configs/insights/',
      method: 'POST',
      paramsType: {} as {
        body: AudienceConfigInput
      },
      responseType: {} as AudienceConfig,
    },
    perspectives: {
      path: '/api/audience-configs/perspectives/',
      method: 'POST',
      paramsType: {} as {
        body: SearchPerspectivesInput
      },
      responseType: {} as SearchPerspectives,
    },
    retrieve: {
      path: '/api/audience-configs/{pk}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          pk: string|number
        }
        query?: GetAudienceConfigInstanceQuery
      },
      responseType: {} as AudienceConfigDetail,
    },
    update: {
      path: '/api/audience-configs/{pk}/',
      method: 'PUT',
      paramsType: {} as {
        body: AudienceConfigInput
        path: {
          pk: string|number
        }
        query?: PutAudienceConfigInstanceQuery
      },
      responseType: {} as AudienceConfig,
    },
  },
  Insights: {
    retrieve: {
      path: '/api/audience-insight/{pk}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          pk: string|number
        }
      },
      responseType: {} as Insights,
    },
  },
  AudienceInsights: {
    by_config: {
      path: '/api/audience-insights/{pk}/by_config/',
      method: 'GET',
      paramsType: {} as {
        path: {
          pk: string|number
        }
      },
      responseType: {} as Insights,
    },
  },
  AudiencePlan: {
    list: {
      path: '/api/audience-plan/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as AudiencePlan[],
    },
    create: {
      path: '/api/audience-plan/',
      method: 'POST',
      paramsType: {} as {
        body: AudiencePlanInput
      },
      responseType: {} as AudiencePlan,
    },
    destroy: {
      path: '/api/audience-plan/{pk}/',
      method: 'DELETE',
      paramsType: {} as {
        path: {
          pk: string|number
        }
      },
      responseType: {} as {},
    },
    retrieve: {
      path: '/api/audience-plan/{pk}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          pk: string|number
        }
      },
      responseType: {} as AudiencePlan,
    },
    partial_update: {
      path: '/api/audience-plan/{pk}/',
      method: 'PATCH',
      paramsType: {} as {
        body: Partial<AudiencePlanInput>
        path: {
          pk: string|number
        }
      },
      responseType: {} as AudiencePlan,
    },
    update: {
      path: '/api/audience-plan/{pk}/',
      method: 'PUT',
      paramsType: {} as {
        body: AudiencePlanInput
        path: {
          pk: string|number
        }
      },
      responseType: {} as AudiencePlan,
    },
  },
  AudienceRegion: {
    list: {
      path: '/api/audience-regions/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as AudienceRegion[],
    },
    retrieve: {
      path: '/api/audience-regions/{pk}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          pk: string|number
        }
      },
      responseType: {} as AudienceRegion,
    },
  },
  Audience: {
    list: {
      path: '/api/audiences/',
      method: 'GET',
      paramsType: {} as {
        query?: GetAudienceListQuery
      },
      responseType: {} as {
        count: number
        next: string
        previous: string
        results: Audience[]
      },
    },
    retrieve: {
      path: '/api/audiences/{pk}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          pk: string|number
        }
        query?: GetAudienceInstanceQuery
      },
      responseType: {} as Audience,
    },
  },
  BuildAudience: {
    create: {
      path: '/api/build-audience/',
      method: 'POST',
      paramsType: {} as {
        body: BuildAudienceInput
      },
      responseType: {} as BuildAudience,
    },
  },
  CampaignSummaryPDF: {
    create: {
      path: '/api/campaign-summary-pdf/',
      method: 'POST',
      paramsType: {} as {
        body: CreateCampaignPdfInput
      },
      responseType: {} as CreateCampaignPdf,
    },
  },
  Campaign: {
    list: {
      path: '/api/campaigns/',
      method: 'GET',
      paramsType: {} as {
        query?: GetCampaignListQuery
      },
      responseType: {} as {
        count: number
        next: string
        previous: string
        results: CampaignListItem[]
      },
    },
    create: {
      path: '/api/campaigns/',
      method: 'POST',
      paramsType: {} as {
        body: WriteTwitterCampaignInput
      },
      responseType: {} as WriteTwitterCampaign,
    },
    ads: {
      path: '/api/campaigns/ads/',
      method: 'GET',
      paramsType: {} as {
        query?: GetAdsQuery
      },
      responseType: {} as {
        count: number
        next: string
        previous: string
        results: AdListItem[]
      },
    },
    adsets: {
      path: '/api/campaigns/adsets/',
      method: 'GET',
      paramsType: {} as {
        query?: GetAdsetsQuery
      },
      responseType: {} as {
        count: number
        next: string
        previous: string
        results: AdsetListItem[]
      },
    },
    reach: {
      path: '/api/campaigns/reach/',
      method: 'GET',
      paramsType: {} as {
        query?: GetReachQuery
      },
      responseType: {} as ReachStats,
    },
    segmentations: {
      path: '/api/campaigns/segmentations/',
      method: 'GET',
      paramsType: {} as {
        query?: GetSegmentationsQuery
      },
      responseType: {} as {
        count: number
        next: string
        previous: string
        results: SegmentationListItem[]
      },
    },
    statstotal: {
      path: '/api/campaigns/statstotal/',
      method: 'GET',
      paramsType: {} as {
        query?: GetStatstotalQuery
      },
      responseType: {} as StatsTotal,
    },
    destroy: {
      path: '/api/campaigns/{pk}/',
      method: 'DELETE',
      paramsType: {} as {
        path: {
          pk: string|number
        }
        query?: DeleteCampaignInstanceQuery
      },
      responseType: {} as {},
    },
    retrieve: {
      path: '/api/campaigns/{pk}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          pk: string|number
        }
        query?: GetCampaignInstanceQuery
      },
      responseType: {} as Campaign,
    },
    partial_update: {
      path: '/api/campaigns/{pk}/',
      method: 'PATCH',
      paramsType: {} as {
        body: Partial<WriteTwitterCampaignInput>
        path: {
          pk: string|number
        }
        query?: PatchCampaignInstanceQuery
      },
      responseType: {} as WriteTwitterCampaign,
    },
    update: {
      path: '/api/campaigns/{pk}/',
      method: 'PUT',
      paramsType: {} as {
        body: WriteTwitterCampaignInput
        path: {
          pk: string|number
        }
        query?: PutCampaignInstanceQuery
      },
      responseType: {} as WriteTwitterCampaign,
    },
  },
  Communities: {
    list: {
      path: '/api/communities/',
      method: 'GET',
      paramsType: {} as {
        query?: GetCommunitiesListQuery
      },
      responseType: {} as {
        count: number
        next: string
        previous: string
        results: AudienceCommunity[]
      },
    },
    retrieve: {
      path: '/api/communities/{pk}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          pk: string|number
        }
        query?: GetCommunitiesInstanceQuery
      },
      responseType: {} as AudienceCommunity,
    },
  },
  Community: {
    list: {
      path: '/api/community/',
      method: 'GET',
      paramsType: {} as {
        query?: GetCommunityListQuery
      },
      responseType: {} as Community[],
    },
    retrieve: {
      path: '/api/community/{pk}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          pk: string|number
        }
        query?: GetCommunityInstanceQuery
      },
      responseType: {} as Community,
    },
  },
  ContentCategories: {
    list: {
      /** Account content categories list */
      path: '/api/content-categories/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as ContentCategories[],
    },
  },
  Subscription: {
    cancel: {
      path: '/api/customer/subscriptions/cancel/',
      method: 'POST',
      paramsType: {} as {
        body: CancellationInput
      },
      responseType: {} as Cancellation,
    },
    checkout: {
      path: '/api/customer/subscriptions/checkout/',
      method: 'POST',
      paramsType: {} as {
        body: CheckoutInput
      },
      responseType: {} as Checkout,
    },
    public_key: {
      path: '/api/customer/subscriptions/public_key/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as Subscription,
    },
    upgrade: {
      path: '/api/customer/subscriptions/upgrade/',
      method: 'POST',
      paramsType: {} as {
        body: UpgradeInput
      },
      responseType: {} as Upgrade,
    },
  },
  ExploreX: {
    create: {
      path: '/api/explore-x/',
      method: 'POST',
      paramsType: {} as {
        body: ExploreXInput
      },
      responseType: {} as ExploreX,
    },
  },
  StandaloneAdAccount: {
    list: {
      path: '/api/external/ad-account/',
      method: 'GET',
      paramsType: {} as {
        query?: GetStandaloneAdAccountListQuery
      },
      responseType: {} as StandaloneAdAccount[],
    },
    create: {
      path: '/api/external/ad-account/',
      method: 'POST',
      paramsType: {} as {
        body: StandaloneAdAccountInput
      },
      responseType: {} as StandaloneAdAccount,
    },
    create_audiences_ad_account: {
      path: '/api/external/ad-account/create_audiences_ad_account/',
      method: 'POST',
      paramsType: {} as {
        body: StandaloneAudienceAdAccountInput
      },
      responseType: {} as StandaloneAudienceAdAccount,
    },
    retrieve: {
      path: '/api/external/ad-account/{pk}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          pk: string|number
        }
        query?: GetStandaloneAdAccountInstanceQuery
      },
      responseType: {} as StandaloneAdAccount,
    },
  },
  StandaloneDescriptionAnalyzer: {
    create: {
      path: '/api/external/analyse-description/',
      method: 'POST',
      paramsType: {} as {
        body: StandaloneDescriptionAnalyzerInput
      },
      responseType: {} as StandaloneDescriptionAnalyzer,
    },
  },
  StandalonePostsAnalyzer: {
    create: {
      /** Standalone create for audience requests */
      path: '/api/external/analyze-posts/',
      method: 'POST',
      paramsType: {} as {
        body: StandalonePostsAnalyzerInput
      },
      responseType: {} as StandalonePostsAnalyzer,
    },
  },
  proxy_event_stream: {
    list: {
      path: '/api/external/api/proxy-event-stream/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as {}[],
    },
  },
  StandaloneAudienceCategory: {
    list: {
      /** Standalone view for audiences */
      path: '/api/external/audience-categories/',
      method: 'GET',
      paramsType: {} as {
        query?: GetStandaloneAudienceCategoryListQuery
      },
      responseType: {} as AudienceCategory[],
    },
    retrieve: {
      /** Standalone view for audiences */
      path: '/api/external/audience-categories/{pk}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          pk: string|number
        }
        query?: GetStandaloneAudienceCategoryInstanceQuery
      },
      responseType: {} as AudienceCategory,
    },
  },
  StandaloneAudienceConfig: {
    list: {
      /** Standalone view for audiences */
      path: '/api/external/audience-configs/',
      method: 'GET',
      paramsType: {} as {
        query?: GetStandaloneAudienceConfigListQuery
      },
      responseType: {} as {
        count: number
        next: string
        previous: string
        results: StandaloneAudienceConfig[]
      },
    },
    create: {
      /** Standalone view for audiences */
      path: '/api/external/audience-configs/',
      method: 'POST',
      paramsType: {} as {
        body: StandaloneAudienceConfigInput
      },
      responseType: {} as StandaloneAudienceConfig,
    },
    autocomplete: {
      /** Standalone view for audiences */
      path: '/api/external/audience-configs/autocomplete/',
      method: 'POST',
      paramsType: {} as {
        body: AutoCompleteInput
      },
      responseType: {} as AutoComplete,
    },
    insights: {
      /** Standalone view for audiences */
      path: '/api/external/audience-configs/insights/',
      method: 'POST',
      paramsType: {} as {
        body: StandaloneAudienceConfigInput
      },
      responseType: {} as StandaloneAudienceConfig,
    },
    perspectives: {
      /** Standalone view for audiences */
      path: '/api/external/audience-configs/perspectives/',
      method: 'POST',
      paramsType: {} as {
        body: SearchPerspectivesInput
      },
      responseType: {} as SearchPerspectives,
    },
    share_via_account: {
      /** Standalone view for audiences */
      path: '/api/external/audience-configs/share-via-account/',
      method: 'POST',
      paramsType: {} as {
        body: StandaloneShareViaAccountAudienceConfigInput
      },
      responseType: {} as StandaloneShareViaAccountAudienceConfig,
    },
    share: {
      /** Standalone view for audiences */
      path: '/api/external/audience-configs/share/',
      method: 'POST',
      paramsType: {} as {
        body: StandaloneShareAudienceConfigInput
      },
      responseType: {} as StandaloneShareAudienceConfig,
    },
    retrieve: {
      /** Standalone view for audiences */
      path: '/api/external/audience-configs/{pk}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          pk: string|number
        }
        query?: GetStandaloneAudienceConfigInstanceQuery
      },
      responseType: {} as StandaloneAudienceConfigDetail,
    },
    update: {
      /** Standalone view for audiences */
      path: '/api/external/audience-configs/{pk}/',
      method: 'PUT',
      paramsType: {} as {
        body: StandaloneAudienceConfigInput
        path: {
          pk: string|number
        }
        query?: PutStandaloneAudienceConfigInstanceQuery
      },
      responseType: {} as StandaloneAudienceConfig,
    },
    download_targeting_package: {
      /** Standalone view for audiences */
      path: '/api/external/audience-configs/{pk}/download_targeting_package/',
      method: 'GET',
      paramsType: {} as {
        path: {
          pk: string|number
        }
        query?: GetDownloadTargetingPackageQuery
      },
      responseType: {} as StandaloneDownloadTargetingPackage,
    },
  },
  StandaloneAudienceRegion: {
    list: {
      /** Standalone view for audiences */
      path: '/api/external/audience-regions/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as AudienceRegion[],
    },
    retrieve: {
      /** Standalone view for audiences */
      path: '/api/external/audience-regions/{pk}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          pk: string|number
        }
      },
      responseType: {} as AudienceRegion,
    },
  },
  StandaloneAudienceRequest: {
    create: {
      /** Standalone create for audience requests */
      path: '/api/external/audience-request/',
      method: 'POST',
      paramsType: {} as {
        body: StandaloneAudienceRequestInput
      },
      responseType: {} as StandaloneAudienceRequest,
    },
    feedback: {
      /** Standalone create for audience requests */
      path: '/api/external/audience-request/feedback/',
      method: 'POST',
      paramsType: {} as {
        body: StandaloneFeedbackInput
      },
      responseType: {} as StandaloneFeedback,
    },
    validate_ad_account: {
      /** Standalone create for audience requests */
      path: '/api/external/audience-request/validate-ad-account/',
      method: 'POST',
      paramsType: {} as {
        body: StandaloneCheckAdAccountAccessInput
      },
      responseType: {} as StandaloneCheckAdAccountAccess,
    },
    validate_email: {
      /** Standalone create for audience requests */
      path: '/api/external/audience-request/validate-email/',
      method: 'POST',
      paramsType: {} as {
        body: PrivateModelInput
      },
      responseType: {} as PrivateModel,
    },
    validate_handle: {
      /** Standalone create for audience requests */
      path: '/api/external/audience-request/validate-handle/',
      method: 'POST',
      paramsType: {} as {
        body: StandaloneHandleInput
      },
      responseType: {} as StandaloneHandle,
    },
  },
  StandaloneAudienceUsedInAds: {
    list: {
      /** Standalone viewset for audiences used in ads */
      path: '/api/external/audience-usages/',
      method: 'GET',
      paramsType: {} as {
        query?: GetStandaloneAudienceUsedInAdsListQuery
      },
      responseType: {} as {
        count: number
        next: string
        previous: string
        results: StandaloneAudienceUsedInAds[]
      },
    },
    create: {
      /** Standalone viewset for audiences used in ads */
      path: '/api/external/audience-usages/',
      method: 'POST',
      paramsType: {} as {
        body: StandaloneDetailAudienceUsedInAdsInput
      },
      responseType: {} as StandaloneDetailAudienceUsedInAds,
    },
    autopilot_suggested_budget: {
      /** Standalone viewset for audiences used in ads */
      path: '/api/external/audience-usages/autopilot-suggested-budget/',
      method: 'GET',
      paramsType: {} as {
        query?: GetAutopilotSuggestedBudgetQuery
      },
      responseType: {} as StandaloneAutopilotSuggestedBudget,
    },
    autopilot_usage_allowed: {
      /** Standalone viewset for audiences used in ads */
      path: '/api/external/audience-usages/autopilot-usage-allowed/',
      method: 'GET',
      paramsType: {} as {
        query?: GetAutopilotUsageAllowedQuery
      },
      responseType: {} as StandaloneAutopilotUsageAllowedAudienceUsedInAds,
    },
    autopilot_usage: {
      /** Standalone viewset for audiences used in ads */
      path: '/api/external/audience-usages/autopilot-usage/',
      method: 'GET',
      paramsType: {} as {
        query?: GetAutopilotUsageQuery
      },
      responseType: {} as StandaloneAutopilotUsageAudienceUsedInAds,
    },
    generate_sheet: {
      /** Standalone viewset for audiences used in ads */
      path: '/api/external/audience-usages/generate-sheet/',
      method: 'POST',
      paramsType: {} as {
        body: StandaloneAudienceUsedInAdsGenerateSheetInput
      },
      responseType: {} as StandaloneAudienceUsedInAdsGenerateSheet,
    },
    reorder_handles: {
      /** Standalone viewset for audiences used in ads */
      path: '/api/external/audience-usages/reorder-handles/',
      method: 'POST',
      paramsType: {} as {
        body: StandaloneReorderHandlesAudienceUsedInAdsInput
      },
      responseType: {} as StandaloneReorderHandlesAudienceUsedInAds,
    },
    share_via_account: {
      /** Standalone viewset for audiences used in ads */
      path: '/api/external/audience-usages/share-via-account/',
      method: 'POST',
      paramsType: {} as {
        body: StandaloneShareViaAccountAudienceUsedInAdsInput
      },
      responseType: {} as StandaloneShareViaAccountAudienceUsedInAds,
    },
    share: {
      /** Standalone viewset for audiences used in ads */
      path: '/api/external/audience-usages/share/',
      method: 'POST',
      paramsType: {} as {
        body: StandaloneShareAudienceUsedInAdsInput
      },
      responseType: {} as StandaloneShareAudienceUsedInAds,
    },
    shared_with: {
      /** Standalone viewset for audiences used in ads */
      path: '/api/external/audience-usages/shared-with/',
      method: 'POST',
      paramsType: {} as {
        body: StandaloneSharedAudienceUsedInAdsInput
      },
      responseType: {} as StandaloneSharedAudienceUsedInAds,
    },
    verify_signed: {
      /** Standalone viewset for audiences used in ads */
      path: '/api/external/audience-usages/verify_signed/',
      method: 'GET',
      paramsType: {} as {
        query?: GetVerifySignedQuery
      },
      responseType: {} as StandaloneAudienceUsedInAds,
    },
    destroy: {
      /** Standalone viewset for audiences used in ads */
      path: '/api/external/audience-usages/{pk}/',
      method: 'DELETE',
      paramsType: {} as {
        path: {
          pk: string|number
        }
        query?: DeleteStandaloneAudienceUsedInAdsInstanceQuery
      },
      responseType: {} as {},
    },
    retrieve: {
      /** Standalone viewset for audiences used in ads */
      path: '/api/external/audience-usages/{pk}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          pk: string|number
        }
        query?: GetStandaloneAudienceUsedInAdsInstanceQuery
      },
      responseType: {} as StandaloneDetailAudienceUsedInAds,
    },
    partial_update: {
      /** Standalone viewset for audiences used in ads */
      path: '/api/external/audience-usages/{pk}/',
      method: 'PATCH',
      paramsType: {} as {
        body: Partial<StandaloneDetailAudienceUsedInAdsInput>
        path: {
          pk: string|number
        }
        query?: PatchStandaloneAudienceUsedInAdsInstanceQuery
      },
      responseType: {} as StandaloneDetailAudienceUsedInAds,
    },
    update: {
      /** Standalone viewset for audiences used in ads */
      path: '/api/external/audience-usages/{pk}/',
      method: 'PUT',
      paramsType: {} as {
        body: StandaloneDetailAudienceUsedInAdsInput
        path: {
          pk: string|number
        }
        query?: PutStandaloneAudienceUsedInAdsInstanceQuery
      },
      responseType: {} as StandaloneDetailAudienceUsedInAds,
    },
    restore: {
      /** Standalone viewset for audiences used in ads */
      path: '/api/external/audience-usages/{pk}/restore/',
      method: 'PATCH',
      paramsType: {} as {
        body: Partial<StandaloneAudienceUsedInAdsInput>
        path: {
          pk: string|number
        }
        query?: PatchRestoreQuery
      },
      responseType: {} as StandaloneAudienceUsedInAds,
    },
  },
  StandaloneInsights: {
    retrieve: {
      /** Standalone view for insights */
      path: '/api/external/audiences-insights/{pk}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          pk: string|number
        }
      },
      responseType: {} as Insights,
    },
  },
  StandaloneProTargetingSummaryPDF: {
    create: {
      path: '/api/external/audiences-pro-targeting-summary/',
      method: 'POST',
      paramsType: {} as {
        body: CreateProTargetingPdfInput
      },
      responseType: {} as CreateProTargetingPdf,
    },
  },
  StandaloneAudience: {
    list: {
      /** Standalone view for audiences */
      path: '/api/external/audiences/',
      method: 'GET',
      paramsType: {} as {
        query?: GetStandaloneAudienceListQuery
      },
      responseType: {} as {
        count: number
        next: string
        previous: string
        results: Audience[]
      },
    },
    retrieve: {
      /** Standalone view for audiences */
      path: '/api/external/audiences/{pk}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          pk: string|number
        }
        query?: GetStandaloneAudienceInstanceQuery
      },
      responseType: {} as Audience,
    },
  },
  StandAloneCampaignOption: {
    list: {
      path: '/api/external/campaign-options/',
      method: 'GET',
      paramsType: {} as {
        query?: GetStandAloneCampaignOptionListQuery
      },
      responseType: {} as StandAloneCampaignOption[],
    },
    retrieve: {
      path: '/api/external/campaign-options/{pk}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          pk: string|number
        }
        query?: GetStandAloneCampaignOptionInstanceQuery
      },
      responseType: {} as StandAloneCampaignOption,
    },
  },
  StandaloneCheckAgencyAdAccount: {
    list: {
      path: '/api/external/check-agency-ad-account/',
      method: 'GET',
      paramsType: {} as {
        query?: GetStandaloneCheckAgencyAdAccountListQuery
      },
      responseType: {} as StandaloneCheckAgencyAdAccount[],
    },
    retrieve: {
      path: '/api/external/check-agency-ad-account/{pk}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          pk: string|number
        }
        query?: GetStandaloneCheckAgencyAdAccountInstanceQuery
      },
      responseType: {} as StandaloneCheckAgencyAdAccount,
    },
  },
  StandaloneCheckCustomAudiences: {
    create: {
      path: '/api/external/check-custom-audiences/',
      method: 'POST',
      paramsType: {} as {
        body: StandaloneCheckCustomAudiencesInput
      },
      responseType: {} as StandaloneCheckCustomAudiences,
    },
  },
  StandaloneConversationTopics: {
    list: {
      path: '/api/external/conversation-topics/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as StandaloneConversationTopic[],
    },
    search: {
      path: '/api/external/conversation-topics/search/',
      method: 'POST',
      paramsType: {} as {
        body: StandaloneSearchConversationTopicsInput
      },
      responseType: {} as StandaloneSearchConversationTopics,
    },
  },
  StandAloneCreateExclusionAudience: {
    create: {
      path: '/api/external/create-exclusion-audience/',
      method: 'POST',
      paramsType: {} as {
        body: StandAloneCreateExclusionAudienceInput
      },
      responseType: {} as StandAloneCreateExclusionAudience,
    },
  },
  DeepThought: {
    account_stats: {
      /** accounts-stats endpoint for xdeepthought.com */
      path: '/api/external/deepthought/accounts-stats/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as DeepThoughtAccountStats,
    },
    enrichment_accounts: {
      /** encrichment accounts endpoint for xdeepthought.com */
      path: '/api/external/deepthought/enrichment-accounts/',
      method: 'POST',
      paramsType: {} as {
        body: DeepThoughtEnrichmentAccountsInput
      },
      responseType: {} as DeepThoughtEnrichmentAccounts,
    },
    enrichments: {
      /** encrichments endpoint for xdeepthought.com */
      path: '/api/external/deepthought/enrichments/',
      method: 'POST',
      paramsType: {} as {
        body: DeepThoughtEnrichmentsInput
      },
      responseType: {} as DeepThoughtEnrichments,
    },
    follower_overlap: {
      /** follower-overlap endpoint for xdeepthought.com */
      path: '/api/external/deepthought/follower-overlap/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as DeepThoughtFollowerOverlap,
    },
    followers: {
      /** followers endpoint for xdeepthought.com */
      path: '/api/external/deepthought/followers/',
      method: 'GET',
      paramsType: {} as {
        query?: GetFollowersQuery
      },
      responseType: {} as {
        count: number
        next: string
        previous: string
        results: DeepThoughtAccount[]
      },
    },
    following: {
      /** following endpoint for xdeepthought.com */
      path: '/api/external/deepthought/following/',
      method: 'GET',
      paramsType: {} as {
        query?: GetFollowingQuery
      },
      responseType: {} as {
        count: number
        next: string
        previous: string
        results: DeepThoughtAccount[]
      },
    },
    me: {
      /** me endpoint for xdeepthought.com */
      path: '/api/external/deepthought/me/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as DeepThoughtAccount,
    },
    posts_and_likes: {
      /** posts-and-likes endpoint for xdeepthought.com */
      path: '/api/external/deepthought/posts-and-likes/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as DeepThoughtPostsAndLikes,
    },
    recently_joined: {
      /** recently joined account check for xdeepthought.com */
      path: '/api/external/deepthought/recently-joined/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as DeepThoughtAccountRecentlyJoined,
    },
    talk_to_x_questions: {
      /** Get summary and follow up questions for Talk to X */
      path: '/api/external/deepthought/talk-to-x-questions/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as DeepThoughtQuestions,
    },
    talk_to_x: {
      /** Talk to X endpoint for xdeepthought.com, logic in serializer */
      path: '/api/external/deepthought/talk-to-x/',
      method: 'POST',
      paramsType: {} as {
        body: DeepThoughtTalkToXInput
      },
      responseType: {} as DeepThoughtTalkToX,
    },
    who_to_follow: {
      /** who-to-follow endpoint for xdeepthought.com */
      path: '/api/external/deepthought/who-to-follow/',
      method: 'POST',
      paramsType: {} as {
        body: DeepThoughtNativePackGeneratorInput
      },
      responseType: {} as DeepThoughtNativePackGenerator,
    },
    post_engagements: {
      /** get post engagements endpoint for xdeepthought.com */
      path: '/api/external/deepthought/{pk}/post-engagements/',
      method: 'GET',
      paramsType: {} as {
        path: {
          pk: string|number
        }
        query?: GetPostEngagementsQuery
      },
      responseType: {} as DeepThoughtPostEngagement,
    },
  },
  StandaloneEvent: {
    list: {
      path: '/api/external/events/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as Event[],
    },
    retrieve: {
      path: '/api/external/events/{pk}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          pk: string|number
        }
      },
      responseType: {} as Event,
    },
  },
  StandaloneNativePackGenerator: {
    create: {
      /** Standalone create for audience requests */
      path: '/api/external/generate-native-pack/',
      method: 'POST',
      paramsType: {} as {
        body: StandaloneNativePackGeneratorInput
      },
      responseType: {} as StandaloneNativePackGenerator,
    },
  },
  StandaloneInterests: {
    list: {
      path: '/api/external/interests/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as StandaloneInterest[],
    },
    search: {
      path: '/api/external/interests/search/',
      method: 'POST',
      paramsType: {} as {
        body: StandaloneSearchInterestsInput
      },
      responseType: {} as StandaloneSearchInterests,
    },
  },
  StandaloneJobTitleTargeting: {
    create: {
      /** Standalone viewset for job title targeting */
      path: '/api/external/job-title-targeting/',
      method: 'POST',
      paramsType: {} as {
        body: StandaloneJobTitleTargetingInput
      },
      responseType: {} as StandaloneJobTitleTargeting,
    },
  },
  StandaloneAutopilotKeywordSuggestionUsage: {
    create: {
      path: '/api/external/keyword-suggestion-usages/',
      method: 'POST',
      paramsType: {} as {
        body: StandaloneAutopilotKeywordSuggestionUsageManyInput
      },
      responseType: {} as StandaloneAutopilotKeywordSuggestionUsageMany,
    },
  },
  StandaloneLanguages: {
    list: {
      path: '/api/external/languages/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as StandaloneLanguage[],
    },
  },
  StandaloneTargetingLocations: {
    list: {
      path: '/api/external/locations/',
      method: 'GET',
      paramsType: {} as {
        query?: GetStandaloneTargetingLocationsListQuery
      },
      responseType: {} as StandaloneTargetingLocation[],
    },
    search: {
      path: '/api/external/locations/search/',
      method: 'POST',
      paramsType: {} as {
        body: StandaloneSearchTargetingLocationsInput
      },
      responseType: {} as StandaloneSearchTargetingLocations,
    },
  },
  StandaloneMarketingMaterial: {
    list: {
      path: '/api/external/marketing-materials/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as MarketingMaterial[],
    },
    retrieve: {
      path: '/api/external/marketing-materials/{pk}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          pk: string|number
        }
      },
      responseType: {} as MarketingMaterial,
    },
    download: {
      path: '/api/external/marketing-materials/{pk}/download/',
      method: 'POST',
      paramsType: {} as {
        body: MarketingMaterialInput
        path: {
          pk: string|number
        }
      },
      responseType: {} as MarketingMaterial,
    },
  },
  StandaloneMoviesTvShows: {
    list: {
      path: '/api/external/movies-tv-shows/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as StandaloneMoviesTvShows[],
    },
    search: {
      path: '/api/external/movies-tv-shows/search/',
      method: 'POST',
      paramsType: {} as {
        body: StandaloneSearchMoviesTvShowsInput
      },
      responseType: {} as StandaloneSearchMoviesTvShows,
    },
  },
  StandaloneNetworkOperators: {
    list: {
      path: '/api/external/network-operators/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as StandaloneNetworkOperator[],
    },
    search: {
      path: '/api/external/network-operators/search/',
      method: 'POST',
      paramsType: {} as {
        body: StandaloneSearchNetworkOperatorsInput
      },
      responseType: {} as StandaloneSearchNetworkOperators,
    },
  },
  StandaloneRecentlyCreatedAudiences: {
    list: {
      path: '/api/external/recent-audiences/',
      method: 'GET',
      paramsType: {} as {
        query?: GetStandaloneRecentlyCreatedAudiencesListQuery
      },
      responseType: {} as StandaloneRecentlyCreatedAudiences[],
    },
    retrieve: {
      path: '/api/external/recent-audiences/{pk}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          pk: string|number
        }
        query?: GetStandaloneRecentlyCreatedAudiencesInstanceQuery
      },
      responseType: {} as StandaloneRecentlyCreatedAudiences,
    },
  },
  StandaloneRelatedKeywords: {
    create: {
      path: '/api/external/related-keywords/',
      method: 'POST',
      paramsType: {} as {
        body: StandaloneRelatedKeywordsInput
      },
      responseType: {} as StandaloneRelatedKeywords,
    },
  },
  StandAloneUploadAudience: {
    create: {
      path: '/api/external/upload-audience/',
      method: 'POST',
      paramsType: {} as {
        body: StandAloneUploadAudienceInput
      },
      responseType: {} as StandAloneUploadAudience,
    },
  },
  GeoLocationUser: {
    geo_location: {
      path: '/api/geo-location/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as GeoLocation,
    },
  },
  CheckHasPixel: {
    has_pixel: {
      path: '/api/has-pixel/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as {},
    },
  },
  HelperDocuments: {
    list: {
      path: '/api/helper-documents/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as HelperDocuments[],
    },
    download: {
      path: '/api/helper-documents/download/',
      method: 'GET',
      paramsType: {} as {
        query?: GetDownloadQuery
      },
      responseType: {} as HelperDocumentsDownload,
    },
    retrieve: {
      path: '/api/helper-documents/{pk}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          pk: string|number
        }
      },
      responseType: {} as HelperDocuments,
    },
  },
  IconoGraphy: {
    list: {
      path: '/api/iconography/',
      method: 'GET',
      paramsType: {} as {
        query?: GetIconoGraphyListQuery
      },
      responseType: {} as Iconography[],
    },
    retrieve: {
      path: '/api/iconography/{pk}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          pk: string|number
        }
        query?: GetIconoGraphyInstanceQuery
      },
      responseType: {} as Iconography,
    },
  },
  Icon: {
    list: {
      path: '/api/icons/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as Icon[],
    },
    retrieve: {
      path: '/api/icons/{pk}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          pk: string|number
        }
      },
      responseType: {} as Icon,
    },
  },
  InsightsInfluencer: {
    list: {
      path: '/api/insights-influencer/',
      method: 'GET',
      paramsType: {} as {
        query?: GetInsightsInfluencerListQuery
      },
      responseType: {} as {
        count: number
        next: string
        previous: string
        results: InsightsInfluencer[]
      },
    },
    retrieve: {
      path: '/api/insights-influencer/{pk}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          pk: string|number
        }
        query?: GetInsightsInfluencerInstanceQuery
      },
      responseType: {} as InsightsInfluencer,
    },
  },
  ProTargetingSummaryPDF: {
    create: {
      path: '/api/insights-pro-targeting-summary/',
      method: 'POST',
      paramsType: {} as {
        body: CreateProTargetingPdfInput
      },
      responseType: {} as CreateProTargetingPdf,
    },
  },
  InsightsSummaryPDF: {
    create: {
      path: '/api/insights-summary/',
      method: 'POST',
      paramsType: {} as {
        body: CreateInsightsPdfInput
      },
      responseType: {} as CreateInsightsPdf,
    },
  },
  JobTitles: {
    list: {
      path: '/api/job-titles/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as JobTitle[],
    },
    retrieve: {
      path: '/api/job-titles/{pk}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          pk: string|number
        }
      },
      responseType: {} as JobTitle,
    },
  },
  Metrics: {
    list: {
      /** Meta information about available campaign metrics */
      path: '/api/metrics/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as MetricInfo[],
    },
  },
  ResetE2E: {
    mock: {
      path: '/api/reset-e2e/mock/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as E2E,
    },
  },
  TypescriptSchemaView: {
    list: {
      path: '/api/schema/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as {}[],
    },
  },
  TargetingCountries: {
    list: {
      /** Targeting countries list */
      path: '/api/targeting-countries/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as TargetingCountries[],
    },
  },
  TargetingInterests: {
    list: {
      /** Targeting interests list */
      path: '/api/targeting-interests/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as TargetingInterests[],
    },
  },
  TargetingLocations: {
    list: {
      /** Targeting cities list */
      path: '/api/targeting-locations/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as TargetingLocations[],
    },
  },
  TeamPublic: {
    retrieve: {
      /** Used to show public name/picture on login page for team */
      path: '/api/teaminfo/{slug}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          slug: string|number
        }
      },
      responseType: {} as TeamPublic,
    },
  },
  UserTeam: {
    list: {
      /** Viewset for my teams (teams). Retrieve by slug. */
      path: '/api/teams/',
      method: 'GET',
      paramsType: {} as {
        query?: GetUserTeamListQuery
      },
      responseType: {} as TeamPublic[],
    },
    create: {
      /** Viewset for my teams (teams). Retrieve by slug. */
      path: '/api/teams/',
      method: 'POST',
      paramsType: {} as {
        body: TeamInput
      },
      responseType: {} as Team,
    },
    retrieve: {
      /** Viewset for my teams (teams). Retrieve by slug. */
      path: '/api/teams/{slug}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          slug: string|number
        }
        query?: GetUserTeamInstanceQuery
      },
      responseType: {} as Team,
    },
    partial_update: {
      /** Viewset for my teams (teams). Retrieve by slug. */
      path: '/api/teams/{slug}/',
      method: 'PATCH',
      paramsType: {} as {
        body: Partial<TeamInput>
        path: {
          slug: string|number
        }
        query?: PatchUserTeamInstanceQuery
      },
      responseType: {} as Team,
    },
    update: {
      /** Viewset for my teams (teams). Retrieve by slug. */
      path: '/api/teams/{slug}/',
      method: 'PUT',
      paramsType: {} as {
        body: TeamInput
        path: {
          slug: string|number
        }
        query?: PutUserTeamInstanceQuery
      },
      responseType: {} as Team,
    },
  },
  TeamAccount: {
    list: {
      path: '/api/teams/{team_id}/accounts/',
      method: 'GET',
      paramsType: {} as {
        path: {
          team_id: string|number
        }
      },
      responseType: {} as AdAccount[],
    },
    retrieve: {
      path: '/api/teams/{team_id}/accounts/{pk}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          team_id: string|number
          pk: string|number
        }
      },
      responseType: {} as AdAccount,
    },
  },
  TeamInvite: {
    list: {
      path: '/api/teams/{team_id}/invites/',
      method: 'GET',
      paramsType: {} as {
        path: {
          team_id: string|number
        }
      },
      responseType: {} as TeamInvite[],
    },
    create: {
      path: '/api/teams/{team_id}/invites/',
      method: 'POST',
      paramsType: {} as {
        body: TeamInviteInput
        path: {
          team_id: string|number
        }
      },
      responseType: {} as TeamInvite,
    },
    accept: {
      path: '/api/teams/{team_id}/invites/accept/',
      method: 'POST',
      paramsType: {} as {
        body: InviteAcceptInput
        path: {
          team_id: string|number
        }
      },
      responseType: {} as InviteAccept,
    },
    retrieve: {
      path: '/api/teams/{team_id}/invites/{pk}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          team_id: string|number
          pk: string|number
        }
      },
      responseType: {} as TeamInvite,
    },
  },
  TeamMember: {
    list: {
      path: '/api/teams/{team_id}/members/',
      method: 'GET',
      paramsType: {} as {
        path: {
          team_id: string|number
        }
      },
      responseType: {} as TeamMember[],
    },
    retrieve: {
      path: '/api/teams/{team_id}/members/{pk}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          team_id: string|number
          pk: string|number
        }
      },
      responseType: {} as TeamMember,
    },
  },
  UploadedAudience: {
    create: {
      path: '/api/uploaded-audiences/',
      method: 'POST',
      paramsType: {} as {
        body: UploadedAudiencesInput
      },
      responseType: {} as UploadedAudiences,
    },
    destroy: {
      path: '/api/uploaded-audiences/{pk}/',
      method: 'DELETE',
      paramsType: {} as {
        path: {
          pk: string|number
        }
      },
      responseType: {} as {},
    },
  },
  UserAdAccount: {
    list: {
      /** List user ad accounts */
      path: '/api/user/accounts/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as UserAdAccount[],
    },
    retrieve: {
      /** List user ad accounts */
      path: '/api/user/accounts/{pk}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          pk: string|number
        }
      },
      responseType: {} as UserAdAccount,
    },
  },
  FollowUnfollow: {
    create: {
      path: '/api/user/follow-unfollow/',
      method: 'POST',
      paramsType: {} as {
        body: FollowUnfollowInput
      },
      responseType: {} as FollowUnfollow,
    },
  },
  GrantedAdAccount: {
    list: {
      /** List/Create/Delete (aka Grant/revoke) access to ad accounts through team memberships */
      path: '/api/user/granted-accounts/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as GrantedAccount[],
    },
    create: {
      /** List/Create/Delete (aka Grant/revoke) access to ad accounts through team memberships */
      path: '/api/user/granted-accounts/',
      method: 'POST',
      paramsType: {} as {
        body: GrantedAccountInput
      },
      responseType: {} as GrantedAccount,
    },
    destroy: {
      /** List/Create/Delete (aka Grant/revoke) access to ad accounts through team memberships */
      path: '/api/user/granted-accounts/{pk}/',
      method: 'DELETE',
      paramsType: {} as {
        path: {
          pk: string|number
        }
      },
      responseType: {} as {},
    },
    retrieve: {
      /** List/Create/Delete (aka Grant/revoke) access to ad accounts through team memberships */
      path: '/api/user/granted-accounts/{pk}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          pk: string|number
        }
      },
      responseType: {} as GrantedAccount,
    },
  },
  TwitterLists: {
    list: {
      path: '/api/user/lists/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as TwitterList[],
    },
    create: {
      path: '/api/user/lists/',
      method: 'POST',
      paramsType: {} as {
        body: TwitterListInput
      },
      responseType: {} as TwitterList,
    },
    destroy: {
      path: '/api/user/lists/{pk}/',
      method: 'DELETE',
      paramsType: {} as {
        path: {
          pk: string|number
        }
      },
      responseType: {} as {},
    },
    retrieve: {
      path: '/api/user/lists/{pk}/',
      method: 'GET',
      paramsType: {} as {
        path: {
          pk: string|number
        }
      },
      responseType: {} as TwitterList,
    },
    partial_update: {
      path: '/api/user/lists/{pk}/',
      method: 'PATCH',
      paramsType: {} as {
        body: Partial<TwitterListInput>
        path: {
          pk: string|number
        }
      },
      responseType: {} as TwitterList,
    },
    update: {
      path: '/api/user/lists/{pk}/',
      method: 'PUT',
      paramsType: {} as {
        body: TwitterListInput
        path: {
          pk: string|number
        }
      },
      responseType: {} as TwitterList,
    },
    members: {
      path: '/api/user/lists/{pk}/members/',
      method: 'POST',
      paramsType: {} as {
        body: TwitterListsMembersInput
        path: {
          pk: string|number
        }
      },
      responseType: {} as TwitterListsMembers,
    },
  },
  MagicLoginLink: {
    create: {
      path: '/api/user/magic-link/',
      method: 'POST',
      paramsType: {} as {
        body: CreateMagicLoginLinkInput
      },
      responseType: {} as CreateMagicLoginLink,
    },
    use: {
      path: '/api/user/magic-link/use/',
      method: 'POST',
      paramsType: {} as {
        body: UseMagicLoginLinkInput
      },
      responseType: {} as UseMagicLoginLink,
    },
  },
  User: {
    me: {
      /** Update user profile (name, etc) */
      path: '/api/user/me/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as User,
    },
    me_put: {
      /** Update user profile (name, etc) */
      path: '/api/user/me/',
      method: 'PUT',
      paramsType: {} as {
        body: UserInput
      },
      responseType: {} as User,
    },
  },
  WebEvents: {
    list: {
      /** Account web events list */
      path: '/api/web-events/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as WebEvents[],
    },
  },
  LogoutView: {
    list: {
      /** Calls Django logout method and delete the Token object
      assigned to the current User object.
      Accepts/Returns nothing. */
      path: '/rest-auth/logout/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as {}[],
    },
    post: {
      /** Calls Django logout method and delete the Token object
      assigned to the current User object.
      Accepts/Returns nothing. */
      path: '/rest-auth/logout/',
      method: 'POST',
      paramsType: {} as {
        body: {}
      },
      responseType: {} as {},
    },
  },
  SocialAccountListView: {
    list: {
      /** List SocialAccounts for the currently logged in user */
      path: '/rest-auth/socialaccounts/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as SocialAccount2[],
    },
  },
  SocialAccountDisconnectView: {
    post: {
      /** Disconnect SocialAccount from remote service for
      the currently logged in user */
      path: '/rest-auth/socialaccounts/{pk}/disconnect/',
      method: 'POST',
      paramsType: {} as {
        body: SocialConnectInput
        path: {
          pk: string|number
        }
      },
      responseType: {} as SocialConnect,
    },
  },
  TwitterLogin: {
    post: {
      path: '/rest-auth/twitter/',
      method: 'POST',
      paramsType: {} as {
        body: TwitterLoginInput
      },
      responseType: {} as TwitterLogin,
    },
  },
  TwitterAccessTokenView: {
    get: {
      /** Receives Twitter redirect
      Requests access token
      Returns token to be used on TwitterConnect/TwitterLogin */
      path: '/rest-auth/twitter/access_token/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as TwitterAccessToken,
    },
  },
  TwitterConnect: {
    post: {
      path: '/rest-auth/twitter/connect/',
      method: 'POST',
      paramsType: {} as {
        body: TwitterConnectInput
      },
      responseType: {} as TwitterConnect,
    },
  },
  TwitterRequestTokenView: {
    get: {
      /** Receives oauth_callback
      Requests request token
      Returns oauth_token which is need to redirect to Twitter */
      path: '/rest-auth/twitter/request_token/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as TwitterRequestToken,
    },
  },
  TwitterOauth2Login: {
    post: {
      path: '/rest-auth/twitter_oauth2/',
      method: 'POST',
      paramsType: {} as {
        body: MyLoginInput
      },
      responseType: {} as MyLogin,
    },
  },
  TwitterCodeChallenge: {
    code_challenge: {
      path: '/rest-auth/twitter_oauth2_challenge/code-challenge/',
      method: 'GET',
      paramsType: {} as {},
      responseType: {} as TwitterAuthParams,
    },
  },
} as const
