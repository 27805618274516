import { MaybeRef, unref } from 'vue'
import {
  Age,
  AgencyName,
  Gender,
  MAPPED_COUNTRY_TO_X,
  MAPPED_X_PLACEMENT_TO_FRIENDLY_NAME,
  Placement,
  ageRanges,
  agencies,
  genders,
  PLACEMENTS,
  GENDER_OPTIONS,
  TEST_AD_ACCOUNTS,
} from './constants'
import { api, PRO_TARGETING_AUTH_TOKEN, schema } from '@/api'
import { messageService } from '@/app'
import { LabeledValueWithOptions } from '@/base'
import { useChromeExtension } from '@/extension'

export function isTestAdAccount() {
  const { adAccountId } = useChromeExtension()
  return TEST_AD_ACCOUNTS.includes(adAccountId.value ?? '')
}

export function isAgency(agency: string | undefined): agency is AgencyName {
  return agencies.some((a) => a === agency)
}

export function getSubdomain() {
  const subdomainRegex = /(?:\/\/)(.*?)\.xprotargeting\.com/
  const subdomain = window.location.href.match(subdomainRegex)?.[1]

  if (subdomain !== 'www') {
    return subdomain
  }
}

export function mapCountryToXCountry(country?: string | undefined) {
  if (!country) return

  return MAPPED_COUNTRY_TO_X[country.toLowerCase()] || country
}

export function isPlacement(placement: string): placement is Placement {
  return PLACEMENTS.includes(placement as Placement)
}

export function friendlyPlacement(placement: string) {
  if (isPlacement(placement)) {
    return MAPPED_X_PLACEMENT_TO_FRIENDLY_NAME[placement]
  }

  return placement
}

export async function getLanguages() {
  const response = await api.call(schema.StandaloneLanguages.list, undefined, {
    headers: { Authorization: `Token ${PRO_TARGETING_AUTH_TOKEN}` },
  })
  return response
}

export async function getNetworkOperators() {
  const response = await api.call(schema.StandaloneNetworkOperators.list, undefined, {
    headers: { Authorization: `Token ${PRO_TARGETING_AUTH_TOKEN}` },
  })
  return response
}

export async function getInterests() {
  const response = await api.call(schema.StandaloneInterests.list, undefined, {
    headers: { Authorization: `Token ${PRO_TARGETING_AUTH_TOKEN}` },
  })
  return response
}

export async function getConversationTopics() {
  const response = await api.call(schema.StandaloneConversationTopics.list, undefined, {
    headers: { Authorization: `Token ${PRO_TARGETING_AUTH_TOKEN}` },
  })
  return response
}

export async function getMoviesTvShows() {
  const response = await api.call(schema.StandaloneMoviesTvShows.list, undefined, {
    headers: { Authorization: `Token ${PRO_TARGETING_AUTH_TOKEN}` },
  })
  return response
}

export async function searchConversationTopics(keywords: string) {
  const response = await api.call(
    schema.StandaloneConversationTopics.search,
    { body: { keywords } },
    { headers: { Authorization: `Token ${PRO_TARGETING_AUTH_TOKEN}` } },
  )
  return response.conversation_topics
}

export async function searchMoviesTvShows(keywords: string) {
  const response = await api.call(
    schema.StandaloneMoviesTvShows.search,
    { body: { keywords } },
    { headers: { Authorization: `Token ${PRO_TARGETING_AUTH_TOKEN}` } },
  )
  return response.movies_tv_shows
}

export async function searchInterests(keywords: string) {
  const response = await api.call(
    schema.StandaloneInterests.search,
    { body: { keywords } },
    { headers: { Authorization: `Token ${PRO_TARGETING_AUTH_TOKEN}` } },
  )
  return response.interests
}

export async function searchNetworkOperators(keywords: string) {
  const response = await api.call(
    schema.StandaloneNetworkOperators.search,
    { body: { keywords } },
    { headers: { Authorization: `Token ${PRO_TARGETING_AUTH_TOKEN}` } },
  )
  return response.network_operators
}

export function isGender(gender: string | null | undefined): gender is Gender {
  return genders.includes(gender as Gender)
}

export function isAge(age: string | null | undefined): age is Age {
  return ageRanges.includes(age as Age)
}

export function mapGender(gender: string | number | null | undefined): Gender {
  if (typeof gender === 'number') {
    if (gender === 1) {
      return 'MALE'
    }

    if (gender === 2) {
      return 'FEMALE'
    }

    return 'ANY'
  }

  if (!isGender(gender)) return 'ANY'

  const g = GENDER_OPTIONS.value.find(
    (g) => g.value.toLowerCase() === gender.toLowerCase() || g.label.toLowerCase() === gender.toLowerCase(),
  )?.value

  if (!g) return 'ANY'

  return g
}

export function removeEmptyValues(obj: MaybeRef<Record<any, any>>): Record<any, any> {
  const newObj = unref(obj)
  Object.keys(newObj).forEach((key) => {
    if (newObj[key] === undefined || newObj[key] === null || newObj[key] === '') {
      delete newObj[key]
    }
  })

  return newObj
}

export async function copyToClipboard(str: string | string[], success?: string) {
  if (!navigator.clipboard) return messageService.error('Failed to copy content. Please try a different browser.')
  if (typeof str !== 'string') str = str.join(', ')
  if (str.length === 0) return messageService.error('Nothing selected to copy.')

  await navigator.clipboard.writeText(str)
  messageService.success(success ?? 'Copied!')
}

export function zip<T = any>(a: T[], b: T[]): T[] {
  const zipped = Array.from(Array(Math.max(b.length, a.length)), (_, i) => [a[i], b[i]])
  const zippedFlat = zipped.flat().filter((el) => el !== undefined)

  return zippedFlat
}

let regions: (LabeledValueWithOptions & { single: boolean; countryCodes: string[] })[] | undefined
export async function getRegions(single?: boolean, withGlobal?: boolean) {
  if (regions) {
    if (withGlobal) {
      return [
        { label: 'Global', value: 'Global', countryCodes: [], single: false, searchableAlternatives: ['all', 'any'] },
        ...regions.filter((r) => !single || r.single),
      ]
    }

    return regions.filter((r) => !single || r.single)
  }

  const response = await api.call(schema.StandaloneAudienceRegion.list, undefined, {
    headers: { Authorization: `Token ${PRO_TARGETING_AUTH_TOKEN}` },
  })
  regions = response.map((r) => ({
    label: r.name,
    value: r.name,
    single: r.is_single_country,
    countryCodes: r.country_codes,
  }))
  const northKorea = regions.findIndex((r) => r.label === "Democratic People's Republic of Korea")
  if (northKorea !== -1) {
    regions[northKorea] = {
      ...regions[northKorea],
      info: 'This country is not available for targeting.',
      selectable: false,
    }
  }
  regions = similarNamedRegions(regions)

  return getRegions(single, withGlobal)
}

function similarNamedRegions(r: NonNullable<typeof regions>) {
  const alternativeNames = {
    netherlands: ['holland'],
    'united kingdom': ['gb', 'great britain', 'uk', 'england', 'scotland', 'wales', 'northern ireland'],
    'united states': ['united states of america', 'usa'],
    czechia: ['czech republic'],
    'united arab emirates': ['uae', 'dubai', 'abu dhabi'],
  }
  const transformedCountryFromX = Object.keys(MAPPED_COUNTRY_TO_X).reduce(
    (acc, key) => {
      acc[key] = [MAPPED_COUNTRY_TO_X[key].toLowerCase()]
      return acc
    },
    {} as Record<string, string[]>,
  )
  const similarNames = Object.assign({}, transformedCountryFromX, alternativeNames)

  return r.map((region) => {
    if (similarNames[region.label.toLowerCase()]) {
      region.searchableAlternatives = similarNames[region.label.toLowerCase()]
    }

    return region
  })
}

export function isNullOrUndefined(value: any): value is null | undefined {
  return value === null || value === undefined
}

export function toNonNullable<T = any>(value: (T | undefined)[]): T[] {
  const array = value.filter((v) => !isNullOrUndefined(v)) as T[]

  return array
}

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>
